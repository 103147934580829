import React, { useEffect } from "react";
import ExSide from "./components/ExSide";
import Header from "./HomeComp/Header";
import OrdersTab from "./components/Orders.tab";
import CandleGraph from "./components/CandleGraph";
import BuyNSell from "./components/BuyNSell";
import Preloader from "./components/PreLoader";
import TradeTab from "./Trade.Tab";
import OrderSystemTab from "./Order.system.tab";
import "./exchange.css";
import { useSelector } from "react-redux";
import Footer from "./HomeComp/Footer";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";


export default function Exchange(props) {

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#282B2D",
      color: "#fff",
      maxWidth: 200,
      // fontSize: theme.typography.pxToRem(12),
      border: "1px solid #282B2D"
    }
  }));




  const { webData } = useSelector((state) => state.websiteDBReducer);
  const {
    coins_loading,
    wallet_loading,
    pending_order_loading,
    close_order_loading,
    user_fav_loading,
    coins
  } = useSelector((state) => state.coinDBReducer);
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = React.useState(true);
  if (!props?.match?.params?.id) props.history?.replace("/exchange/btc-inr");

  useEffect(() => {
    let match = 1;
    const browser_currency_pair = props?.match?.params?.id.split("-");
    let vll = Object.values(coins);

    if (vll.length) {
      console.log("vll: ", vll, browser_currency_pair);
      vll.forEach((item) => {
        // console.log("not inr",browser_currency_pair[1].toUpperCase(), item.symbol);
        if (item.symbol === browser_currency_pair[0].toUpperCase()) {
          if (browser_currency_pair[1].toUpperCase() !== "INR") {
            vll.forEach((it) => {
              if (it.symbol === browser_currency_pair[1].toUpperCase()) {
                match = 0;
              }
            });
          } else {
            match = 0;
          }
        }
      });
      if (match === 1) {
        props.history?.replace("/");
      }
      // if (!coins) {
      //   let nurl = vll[0]
      //     ? vll[0].symbol + "-" + vll[0]?.pairing_currency
      //     : "btc-inrx";
      //   props.history?.replace("/exchange/btc-inrx");
      // }
      if (isLoggedIn) {
        if (
          coins_loading == false &&
          wallet_loading == false &&
          pending_order_loading == false &&
          close_order_loading == false &&
          user_fav_loading == false
        )
          document.title =
            props?.match?.params?.id?.toUpperCase() +
            " " +
            webData.website_title +
            " Exchange";
        setLoading(false);
      } else {
        if (coins_loading == false) setLoading(false);
      }
    }
  }, [coins_loading, wallet_loading, coins.length, user_fav_loading]);



  return (
    <>
      <Header {...props} />

      {loading ? (
        <Preloader />
      ) : (
        <div className="row exchange_styled">
          {/* <div className="col-12 col-md-7 col-lg-12">
          <marquee style={{fontSize: '2em', color:'red' }} behavior="alternate">Sorry for the inconvenience but system is under maintenance.We’ll be back soon thank you!</marquee>
           </div> */}
          <div className="col-12 col-md-7 col-lg-8 ps-1 pe-1 py-0 mt-2">
            <CandleGraph {...props} />
            <OrdersTab {...props} />
          </div>

          {/* <div className="col-12 col-md-3 col-lg-2 p-1">
            <ExSide {...props} />
          </div> */}

          <div className="col-12 col-md-3 col-lg-4 mt-2">
            <div className="row">
              <div className="col-12 col-lg-6 col-md-4 px-1 py-0 ">            
                <OrderSystemTab {...props} />
              </div>

              <div className="col-12 col-lg-6 col-md-4 ps-1 py-0">
                <TradeTab {...props} />
              </div>
            </div>

            <div className="row">
              <BuyNSell {...props} />
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}
