import React from "react";
import {
  BsFacebook,
  BsTelegram,
  BsInstagram,
  BsLinkedin
} from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { adminurl } from "../redux/helpers/api_functions_new";
import { useSelector } from "react-redux";

export default function Footer() {
  const { webData } = useSelector((state) => state.websiteDBReducer);
  AOS.init({ duration: 1000 });
  return (
    <>
      <div className="container-fluid py-5 footer_div">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-logo">
                <img
                  className="logo_height"
                  src={`${adminurl}${webData?.logo_img_name}`}
                  alt="logo"
                />
              </div>
              <p className="pt-2 pe-5">
                A crypto exchange, or a digital currency exchange (DCE), is a
                business that allows customers to trade cryptocurrencies for
                other assets, such as conventional fiat money or other digital
                currencies.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h5>About Us</h5>
              <ul className="footer_list">
                <li>
                  <a href="/about">About</a>
                </li>

                <li>
                  <a href="/downloads">Downloads</a>
                </li>

                <li>
                  <a>Business Contacts</a>
                </li>
                <li>
                  <a href="/community">Community</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 my-2">
              <h5>Service</h5>
              <p>INR Trade</p>
              <p>Request Token</p>
              <p>Unique trade</p>
              <p>Safe trade</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 my-2 mmysocial">
              <h5>Social Links </h5>
              <div>
                <ul className="footer_list">
                  <li>
                    <a
                      className="d-flex align-items-center"
                      href="https://twitter.com/BitPluglab"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <AiFillTwitterCircle className="fs-4" />
                      <span className="align-bottom ms-2 fs-14">Twitter</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="d-flex align-items-center"
                      href="https://t.me/BitPluglab"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsTelegram className="fs-4" />
                      <span className="align-bottom ms-2 fs-14">Telegram</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="d-flex align-items-center"
                      href="https://linkedin.com/company/BitPluglab"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsLinkedin className="fs-5" />
                      <span className="align-bottom ms-2 fs-14">Linkedin</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="divider"/>
        <div className="d-flex justify-content-center footer-bottom">
          <span className="" style={{ padding: "10px" }}>
            © 2022 BitPlug.
          </span>
          <a
            
            href="/privacy"
          >
            Privacy policy
          </a>
          <a
            
            href="/terms_&_conditions"
          >
            Terms & Conditions
          </a>
          <a
           
            href="/api-docs"
            target="_blank"
          >
            API documentation
          </a>
        </div>
      </div>
    </>
  );
}
