import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./chart.css";
import { toggleFav } from "../redux/actions/coinDBAction";
import TVChartContainer from "./CandleChart";
import { N_getVHL, N_RemoteStatus } from "../redux/helpers/api_functions_new";
import $ from "jquery";
import ExSide from "./ExSide";
import { getRound } from "../redux/helpers/helper_functions";

export default function CandleGraph(props) {
  const dispatch = useDispatch();
  // const url = "https://api.bitflash.io/api";
  // const [fullexe, setFUllexw] = React.useState(false);
  const { coins, currency_graph, user_fav_pairing, paired_curency_price } =
    useSelector((state) => state.coinDBReducer);
  const [isFav, setIsFav] = React.useState(false);
  const [current_price, currentPrice] = React.useState(0);
  const [volume, setVolume] = useState(0);
  const [high, setHigh] = useState(0);
  const [low, setLow] = useState(0);
  const [close, setClose] = useState(0);
  const [per, setPercet] = useState(0);
  const [newgetchart, NewGetChart] = React.useState(false);
  const { user } = useSelector((state) => state.AuthReducer);
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const filter = "1h";
  const [prev_symbol, prevSymbol] = React.useState("");

  const coin = props.match.params.id.split("-");
  const SelCurency = coin && coin[1] ? coin[1].toUpperCase() : "";
  const data = Object.values(coins).find((d) => {
    if (d.symbol === coin[0].toUpperCase()) {
      return d;
    }
  });
  useEffect(() => {
    let match = user_fav_pairing.find((d) => d === data.symbol.toUpperCase());
    setIsFav(match ? true : false);
  }, [...user_fav_pairing, ...coin]);

  useEffect(() => {
    N_getVHL(props.match.params.id).then((data) => {
      if (data.status == 200) {
        setVolume(data.vhl.todays_v);
        setHigh(data.vhl.todays_h);
        setLow(data.vhl.todays_l);
        setClose(data.vhl.todays_c);
        setPercet(data.vhl.todays_pc);
      }
    });
  }, [props.match.params.id]);
  function getChart(symbol, symbol2,) {
    prevSymbol(symbol);
    // console.log("getChart1: ", symbol, symbol2);
    return (
      <>
        <TVChartContainer symbols={symbol} pre_symbols={symbol2} />
      </>
    );
  }

  useEffect(() => {
    let coinsym = coin[0] + "-" + coin[1];
    if (paired_curency_price && data && SelCurency) {
      N_RemoteStatus(coin[0], coin[1]).then((dt) => {
        let inrPrice = data.current_price ? data.current_price : 1;
        let selPrice =
          SelCurency == "INR"
            ? 1
            : paired_curency_price[SelCurency]
            ? paired_curency_price[SelCurency]
            : 1;
        let fPrice = inrPrice * selPrice ? inrPrice / selPrice : 1;
        if (dt.status == 200) {
          const coinStatus = dt?.remoteTradingData?.update_price;
          if (SelCurency == "USDT" && coinStatus) {
            currentPrice(data.usdt_price);
          } else if (SelCurency == "BTC" && coinStatus) {
            currentPrice(data.btc_price);
          } else if (SelCurency == "INR" && coinStatus) {
            currentPrice(data.inr_price);
          } else if (SelCurency == "BTP" && coinStatus) {
            currentPrice(data.vrx_price);
          } else {
            currentPrice(fPrice);
          }
        } else {
          currentPrice(fPrice);
        }
      });
    }
    NewGetChart(getChart(coinsym, prev_symbol));
  }, [...coin, filter, currency_graph]);
  return (
    <>
      {/* <div
        className="sc-dTdPqK p-0"
        style={{ backgroundColor: webData.bg_color_code }}
      >
        <div className="sc-bdVaJa rSmgz py-0 px-0 graph-head">
          <div height="24px" width="12px" className="sc-bdVaJa gSxurx"></div>
          <div className="sc-bdVaJa lmEScu p-0">
            <div className="sc-bdVaJa dveUWY p-0">
              <h1 color="#1C1B21" className="sc-bwzfXH iHECUo p-0">
                {props.match.params.id.toUpperCase().replace("-", "/")}
              </h1>
              <span color="#9b9b9b" className="sc-bwzfXH ksDqJJ p-0 px-2">
                {data?.name}
              </span>
            </div>
            <div className="sc-bdVaJa sc-dliRfk iUXzPH p-0">
              <span color="#929292" className="sc-bwzfXH kgoTtc p-0">
                Last Price
              </span>
              <span
                cursor="pointer"
                color="#1C1B21"
                className="sc-bwzfXH jaArUU"
              >
                {current_price}
              </span>
              <span
                onClick={() => {
                  dispatch(toggleFav(user?.params ? user.params.user_id : user.user_id, (data?.symbol).toUpperCase()));
                  if (isLoggedIn) setIsFav(!isFav);
                }}
              >
                <i
                  className={`fas ${
                    isFav ? "fa-star" : "fa-star-o"
                  } text-warning`}
                ></i>
              </span>
              <div className="sc-bdVaJa sc-jVODtj jZzDMB p-0">
                <i className="mdi mdi-star-outline mdi-18px"></i>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div class="card">
        <div class="card-body p-0">
          <div class="currency-market">
            <div class="currency-header-left currency_header">
              <div class="currency-header-left-inner" id="hide_section">
                <div class="d-flex">
                  <div>
                    <img
                      width="20px"
                      height="20px"
                      class="me-2 position-relative top9"
                      src={data?.icon}
                      alt=""
                    />
                  </div>
                  <div>
                    <a
                      class="fs-16"
                      data-bs-toggle="modal"
                      data-bs-target="#currency_price_modal"
                      href="/exchange/btc-inrx"
                    >
                      {props.match.params.id.toUpperCase().replace("-", "/")}{" "}
                      <span className="small text-muted ms-2">
                        ({data?.name}){" "}
                      </span>
                    </a>
                  </div>
                </div>
                <div class="arrow_down"></div>
              </div>

              <div class="hide-currency-market">
                <div class="exchange-pairs">
                  <div class="">
                    <ExSide {...props} />
                  </div>
                </div>
              </div>
            </div>
            <div class="currency-header-right">
              <div className="currency-header-right-inner">
                <div class="value-block">
                  <span class="heading">Last Price</span>
                  <span class="heading_value">{getRound(current_price)}</span>
                  <span
                    onClick={() => {
                      dispatch(
                        toggleFav(
                          user?.params ? user.params.user_id : user.user_id,
                          (data?.symbol).toUpperCase()
                        )
                      );
                      if (isLoggedIn) setIsFav(!isFav);
                    }}
                  ></span>
                </div>

                <div className="value-block">
                  <span className="heading">Volume</span>
                  <span className="heading_value">{getRound(volume)}</span>
                </div>
                <div className="value-block">
                  <span className="heading">High</span>
                  <span className="heading_value">
                    <span class="high_24h">{high}</span>
                    {/* <i className="fa fa-caret-up ms-2"></i> */}
                  </span>
                </div>
                <div className="value-block">
                  <span className="heading">Low</span>
                  <span className="heading_value">
                    <span class="low_24h">{low}</span>
                    {/* <i className="fa fa-caret-down ms-2"></i> */}
                  </span>
                </div>
                {/* <div className="value-block">
                  <span color="#929292" className="heading">
                    CLOSE
                  </span>
                  <span color="#1C1B21" className=" sc-bwzfXH izvMda">
                    {close}
                  </span>
                </div> */}
                <div className="value-block"> 
                  <span className="heading">AVG</span>
                  <span className="heading_value">
                    {data?.price_change_percentage_1h} %
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="sc-bdVaJa sc-kUaPvJ kZeBBS row"
            
          >
            <div className="sc-bdVaJa sc-giadOv iIMfMq col-12 col-md-8 col-lg-8"></div>
          </div>
          <div id="candleCart1">{newgetchart}</div>
        </div>
      </div>
    </>
  );
}
