import React from "react";
import { useSelector } from "react-redux";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FaCoins, FaSortDown, FaSortUp } from "react-icons/fa";
import { BiGitCompare } from "react-icons/bi";
import { HiCubeTransparent } from "react-icons/hi";
import Card from "./Card";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Header";
import Footer from "./Footer";
import { getRound } from "../redux/helpers/helper_functions";

export default function Markets() {
  AOS.init();
  const { coins, campare_currency } = useSelector((state) => state.coinDBReducer);
  let coins1 = Object.values(coins);
  // console.log(coins1)
  const market_child =
    coins1 &&
    coins1.map((item, index) => {
      let usdtprize = coins1 && coins1.find((item) => item.symbol == "USDT");
      if(item.symbol !='INR' && item.symbol !='USDT')
      return (
        <>
          <tr>
            <td>
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src={item.icon}
                    alt="coin symbole"
                    className="market_coin_img"
                    //style={{ width: "50px" }}
                  />
                </div>
                <div className="product_name">
                  <div className="mb-0 fw-bold">{item.symbol}</div>
                  <div className="text-muted fs-12"> {item.name}</div>
                </div>{" "}
              </div>
            </td>
            <td className="text-end">
              $
              {getRound(
                item.current_price / usdtprize.current_price
              )}
            </td>
            <td className="text-end">
              <span
                className={
                  item.direction_inr === "up" ? "text-green " : "text-red"
                }
              >
                {getRound(item.price_change_percentage_1h)}%{" "}
                {item.direction === "up" ? (
                  <BsArrowUpShort />
                ) : (
                  <BsArrowDownShort />
                )}
              </span>
            </td>
            <td className="text-end">{getRound(item.market_cap)}</td>

            <td className="text-end">
              <a href="#" className="anchor_link">
                Details
              </a>
              <div className="verticle_line"></div>
              <a
                href={`/exchange/${item.symbol.toLowerCase()}-${campare_currency}`}
                className="anchor_link"
              >
                Trade
              </a>
            </td>
          </tr>
        </>
      );
    });
  return (
    <>
      <Header />

      <div className="page-content">
        <div class="tab_area">
          <div class="container custom_pills">
            <div class="nav nav-pills" id="nav-tab" role="tablist">
              {/* <div
                class="nav-item nav-link  me-4"
                href="#favourites"
                data-toggle="tab"
              >
                <i className="fa-regular fa-star me-1"></i>Favourites
              </div> */}
              <div
                class="nav-item nav-link  me-4 active"
                href="#all"
                data-toggle="tab"
              >
                Spot
              </div>
            </div>
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-pane fade active show" id="all">
            <div className="spacer">
              <div className="container">
                <div className="para_heading ">Market Trend</div>
                <p className="mb-4 text-lgray">
                  {" "}
                  Find promising coins and great opportunities!
                </p>
                <div className="mt-3">
                  <div className="col-lg-12 col-md-12 col-sm-12 table-responsive">
                    <table className="table global_table">
                      <thead>
                        <tr>
                          <th>
                            <div className="d-flex align-items-center">
                              <span className="">Product</span>
                              <div className="mx-1">
                                <div className="position-absolute">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div>
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="">Price</div>
                              <div className="mx-1">
                                <div className="position-absolute">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div>
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">24H Change</span>
                              <div className="mx-1">
                                <div className="position-absolute">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div>
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">Market Cap</span>
                              <div className="mx-1">
                                <div className="position-absolute">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div>
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>

                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">Action</span>
                              <div className="mx-1">
                                <div className="position-absolute">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div>
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{market_child}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="favourites">favourites</div>
          <div className="tab-pane fade" id="spot">spot</div>
          <div className="tab-pane fade" id="futures">Futures</div>
          <div className="tab-pane fade" id="listing">New Listings</div>
        </div>
      </div>

      <Footer />
    </>
  );
}
