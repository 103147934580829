import React, { useEffect } from "react";
import ProfileSidebar from "./components/ProfileSidebar";
import Header from "./HomeComp/Header";
import Switch from "react-switch";
import { useSelector } from "react-redux";

export default function Fees(props) {
  const [get_cstcoin, setcstCoin] = React.useState(0);
  const [set_enable, setEnable] = React.useState(0);
  let { paired_curency } = useSelector((state) => state.coinDBReducer);
  useEffect(() => {
        paired_curency.map((item) => {
          if (item.paired_with === "is_paired_vrx") {
            setcstCoin(item);
          }
        });
  }, []);
  return (
    <div>
      <Header {...props} />
      <div className="col-lg-12">
        
          <ProfileSidebar {...props} />
        
        <div
          className="main-content"
         
        >
          <div className="mt-3">
            <h4 className="mb-3"> Fee</h4>           
          <div className="card">
            <div className="card-header"> Pay trading fees with {get_cstcoin.currency_coin}</div>
          <div className="card-body">
              <div className="col-lg-5 col-12 hero_div">
                <div className="d-flex align-items-center justify-content-between">
                <div className="fs-14">
                    Enable this option to pay trading fees with:{" "}
                  </div>
                  <div className="text-end">
                    <Switch
                      onChange={(e) => setEnable(set_enable ? 0 : 1)}
                      checked={set_enable}
                    />
                  </div>
                 </div>
               
              </div>
            
              <ul className="text-muted mb-2">
                    <li className="ml-3">
                    <i class="fas fa-hand-point-right me-2"></i>
                      {get_cstcoin.currency_coin} you buy from the exchange
                    </li>
                    <li className="ml-3">
                    <i class="fas fa-hand-point-right me-2"></i>
                      Unlocked {get_cstcoin.currency_coin} balance reserved for
                      trading fees
                    </li>
                  </ul>
                  <div className="text-secondary">
                    <b>Note:</b> You'll get 50% discount if you pay fees via{" "}
                    {get_cstcoin.currency_coin}
                  </div>
               
               </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
