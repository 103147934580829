import React, { useEffect, useState } from "react";
import ProfileSidebar from "./components/ProfileSidebar";
import Header from "./HomeComp/Header";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import { N_checkKYCStatus } from "./redux/helpers/api_functions_new";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { GiMagnifyingGlass } from "react-icons/gi";

export default function UserKYC(props) {
  const { user } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = React.useState(true);
  const [kyc, setKyc] = React.useState();
  const { webData } = useSelector((state) => state.websiteDBReducer);

  useEffect(async () => {
    N_checkKYCStatus(user?.params ? user.params.token : user.token)
      .then((d) => {
        if (d.status === 200) {
          if (d.params.kyc_status === 1) {
            setKyc(d.params.kyc_status);
            setLoading(false);
          } else if (d.params.kyc_status === -1) {
            setKyc(d.params.kyc_status);
            setLoading(false);
          }
          setLoading(false);
        } else {
          NotificationManager.error(d.message);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      <Header {...props} />

      <ProfileSidebar {...props} />

      <div className="main-content">
        <div className="mt-3">
          <h4 className="mb-3">KYC</h4>

          <div className="card">
            <div className="card-body">
              {loading ? (
                <Loader />
              ) : kyc === 1 ? (
                <div className="">
                  <h5>
                    Congratulations! Your {webData.website_title} account is
                    approved.{" "}
                  </h5>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => props.history.push("/exchange/btc-inrx")}
                  >
                    Start Trading
                  </button>
                </div>
              ) : kyc === -1 ? (
                <div
                  className="text-secondary d-flex align-content-center"
                  style={{ fontWeight: "bold", fontSize: "14px" }}
                >
                  <span className="text-info mx-2" id="rv">
                    <GiMagnifyingGlass size={26} />
                  </span>
                  Your KYC is under Review. please for wait 72 hours.
                </div>
              ) : (
                <div className="d-flex align-items-center gap-3">
                  <h6>Please Complete Your KYC and Start Trading</h6>
                  <a
                    type="button"
                    className="btn btn-primary"
                    onClick={() => props.history.push("/kyc-verify")}
                  >
                    Start KYC
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
