import React, { useState, useEffect } from "react";
import Header from "./HomeComp/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./login.css";
import {
  isEmail,
  isNum,
  isPass
} from "./redux/helpers/form-validator.functions";
import { user_Login, user_authentication } from "./redux/actions/authActions";
import { useGoogleLogin } from "@react-oauth/google";
// import { adminurl } from "../redux/helpers/api_functions_new";
import {
  N_getGoogleAuth,
  N_resendOTP,
  N_sendOTPMobileEmail,
  N_verifyOTPNew,
  socialLogin
} from "./redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import { AUTH_LOGIN } from "./redux/constant";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
export default function Login(props) {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  if (isLoggedIn) props.history?.replace("/");
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [phone, setphone] = useState();
  const [activetab, setActivetab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState();
  const [otp, setotp] = useState();
  const [verifyotp, setverifyOtp] = useState();
  const [isOTPSent, setIsOTPSent] = useState(false);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const [user, setUser] = useState([]);
  const [eye, seteye] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [authenticator, setAuthenticator] = useState(0);
  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      seteye(false);
    } else {
      _idv.setAttribute("type", "text");
      seteye(true);
    }
  };
  const submit = (e) => {
    e.preventDefault();
    setIsAuthenticating(true);
    if (authenticator == 2 || authenticator == 1) {
      dispatch(
        user_Login(
          email,
          phone,
          password,
          otp,
          () => {
            setLoading(false);
            props.history?.replace("/");
          },
          () => setLoading(false)
        )
      );
    } else {
      if ((isEmail(email) || isNum(phone)) && isPass(password)) {
        N_getGoogleAuth(email, phone, password).then((data) => {
          if (data.status === 200 && data.authenticator_status === 0) {
            setLoading(true);
            dispatch(
              user_Login(
                email,
                phone,
                password,
                otp,
                () => {
                  setLoading(false);
                  props.history?.replace("/");
                },
                () => setLoading(false)
              )
            );
          } else if(data.status === 300) {
            setIsOTPSent(true);
          } else {
            setAuthenticator(data.authenticator_status);
            NotificationManager.error(data.message);
          }
        });
      } else {
        NotificationManager.error("Invalid Input");
      }
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    N_verifyOTPNew(
      email,
      phone,
      verifyotp
    ).then((res) => {
      if (res.status == 200) {
        NotificationManager.success(
          res.message
        );
        dispatch({ type: AUTH_LOGIN, data: res });

      } else {
        NotificationManager.error(
          res.message
        );
      }
    });
    
  };


  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      socialLogin(codeResponse.access_token).then((res) => {
        if (res.status) {
          NotificationManager.success(res.message);
          dispatch({ type: AUTH_LOGIN, data: res });
          props.history?.replace("/");
        } else {
          NotificationManager.success(res.message);
        }
      });
    },
    onError: (error) => console.log("Login Failed:", error)
  });

  return (
    <>
      <Header {...props} />
      <div class="container-fluid">
      <div className="page-content">
      <div class="row">
          <div class="col-md-6 d-none d-lg-block login_left_bg ">
            
            <div className="d-flex justify-content-center align-items-center login_page_minheight">
              <div class="text-center">
                <img src="/img/login-bg.png" className="login-img " />
              </div>
            </div>
          </div>

          <div class="col-md-6">  
            
            <div class="d-flex align-items-center login_page_minheight">
              <div class="container">
                <div class="row">
                {isOTPSent? <div class="m-auto login_window signupform cstm_form">
                    <div className="page-content-title">
                      <h3 className="mb-lg-5">Enter the 6-digit verification code we sent to {email?email:phone}</h3>
                      <h6>For security, we need to verify it's you.</h6>
                    </div>
                    <form
                      className=""
                      method="post"
                      id="signupform"
                    >
                      <div className="form-group">
                        <small id="msg">Error Message</small>
                      </div>
                      <div className={`form-group`}>
                        
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            name="user_otp"
                            id="user_otp"
                            required=""
                            value={verifyotp}
                            onChange={(e) => setverifyOtp(e.target.value)}
                            placeholder="Enter Verfication Code"
                          />
                          <span className="input-group-text">
                            <div className="">
                              <div className="">
                                <button
                                  className="btn border-0 text-primary border-start rounded-0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    N_resendOTP(email, phone).then(
                                      (res) => {
                                        if (res.status == 200) {
                                          NotificationManager.success(
                                            res.message
                                          );
                                        } else {
                                          NotificationManager.error(res.message);
                                        }
                                      }
                                    );
                                  }}
                                >
                                  Resend
                                </button>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="d-grid mt-3">
                        <button
                          type="button"
                          id="btn_submit"
                          className="btn login_btn"
                          onClick={(e)=>{
                            onSubmit(e);
                          }}
                        >
                          {loading ? (
                            <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                          ) : null}
                          <span id="reg">Verify</span>
                        </button>
                      </div>
                    </form>
                  </div>:
                  <div class="m-auto login_window signupform cstm_form">
                    <div className="page-content-title">
                      <h3 className="mb-lg-5">Log In</h3>
                    </div>
                    {/* <p class="text-muted mb-4 mt-0">
                      Sign In your account to enjoy trading.
                    </p> */}

                    <div className="custom_pills">
                      <div className="nav nav-pills mb-3" id="myTab">
                        <div
                          className="nav-link text-muted active fs-16"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home"
                          style={{ height: "28px" }}
                          onClick={() => {
                            setActivetab(0);
                          }}
                        >
                          {/* <i className="fa fa-envelope me-2"></i> */}
                          Email
                        </div>

                        <div
                          className="nav-link text-muted fs-16 "
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          style={{ height: "28px" }}
                          onClick={() => {
                            setActivetab(1);
                          }}
                        >
                          {/* <i className="fa fa-phone me-2"></i>  */}
                          Phone Number
                        </div>
                      </div>
                    </div>

                    <form
                      className="mt-5"
                      method="post"
                      id="loginForm"
                      onSubmit={submit}
                      autoComplete="off"
                    >
                      <div className="form-group">
                        <small
                          id="msg"
                          className="mt-2 "
                          style={{ fontSize: "15px", marginTop: "20px" }}
                        >
                          Error Message
                        </small>
                      </div>
                      {activetab == 0 ? (
                        <div className="form-group mb-3">
                          {/* <label htmlFor="user_password" className="">
                            Email/Sub-Account
                          </label> */}
                          <input
                            type="email"
                            name="user_email"
                            className="form-control"
                            value={email}
                            autoComplete="off"
                            readOnly
                            onFocus={(e) => {
                              e.target.removeAttribute("readOnly");
                            }}
                            id="user_email"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                          />
                          {/* <i className="fas fa-check-circle"></i>
                          <i className="fas fa-exclamation-circle"></i>
                          <small></small> */}
                        </div>
                      ) : (
                        <div className="form-group mb-4">
                          {/* <label>Phone Number</label> */}
                          <input
                            type="phone"
                            name="user_phone"
                            className="form-control"
                            autoComplete="off"
                            readOnly
                            onFocus={(e) => {
                              e.target.removeAttribute("readOnly");
                            }}
                            value={phone}
                            id="user_phone"
                            onChange={(e) => setphone(e.target.value)}
                            placeholder="Phone Number"
                          />
                          {/* <i className="fas fa-check-circle"></i>
                          <i className="fas fa-exclamation-circle"></i>
                          <small></small> */}
                        </div>
                      )}
                      {authenticator == 2 || authenticator == 1 ? (
                        <div className="form-group mb-4">
                          <input
                            type="number"
                            name="user_otp"
                            className="form-control"
                            required=""
                            value={otp}
                            id="user_otp"
                            onChange={(e) => setotp(e.target.value)}
                            placeholder="Enter OTP"
                          />
                          {/* <i className="fas fa-check-circle"></i>
                          <i className="fas fa-exclamation-circle"></i>
                          <small></small> */}
                        </div>
                      ) : null}
                      <div className="form-group">
                        {/* <label htmlFor="user_password" className="">
                          Login Password
                        </label> */}

                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control"
                            name="user_password"
                            id="pass"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required=""
                            placeholder="Password"
                          />
                          <span className="input-group-text">
                            <a
                              href="#view_qr"
                              className=""
                              onClick={(e) => {
                                hsPassword("pass");
                              }}
                            >
                              {/* <i className="far fa-eye"></i> */}
                              {eye ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#666"
                                  className="bi bi-eye-slash-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#666"
                                  className="bi bi-eye-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                </svg>
                              )}
                            </a>
                          </span>
                        </div>
                        {/* <i className="fas fa-check-circle"></i>
                        <i className="fas fa-exclamation-circle"></i>
                        <small id="passerr"></small> */}
                      </div>
                      <div className="text-end">
                        <Link to="/forget" className="text-lgray fs-12">
                          Forgot Password ?
                        </Link>
                      </div>

                      <div className="d-grid mt-3 mb-3">
                        <button
                          type="submit"
                          id="login_btn"
                          className="btn login_btn"
                        >
                          {loading ? (
                            <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                          ) : null}
                          <span id="reg">Log In</span>
                        </button>
                      </div>
                    </form>
                    {/* <div className="my-3 text-center">
                      Or Log In with any one of these
                    </div> */}
                    <div className="d-grid">
                      <button
                        className="login-with-google-btn"
                        onClick={() => googleLogin()}
                      >
                        <img
                          src="./img/google.png"
                          width={20}
                          className="me-2"
                        />{" "}
                        Sign in with Google
                      </button>

                      {/* <ConnectButton.Custom>
                        {({
                          account,
                          chain,
                          openAccountModal,
                          openChainModal,
                          openConnectModal,
                          authenticationStatus,
                          mounted
                        }) => {
                          // Note: If your app doesn't use authentication, you
                          // can remove all 'authenticationStatus' checks
                          const ready =
                            mounted && authenticationStatus !== "loading";
                          const connected =
                            ready &&
                            account &&
                            chain &&
                            (!authenticationStatus ||
                              authenticationStatus === "authenticated");

                          return (
                            <div
                              className="d-grid mt-3"
                              {...(!ready && {
                                "aria-hidden": true,
                                style: {
                                  opacity: 0,
                                  pointerEvents: "none",
                                  userSelect: "none"
                                }
                              })}
                            >
                              {(() => {
                                if (!connected) {
                                  return (
                                    <button
                                      class="btn login_btn_outline"
                                      onClick={openConnectModal}
                                      type="button"
                                    >
                                      <i className="fa fa-wallet me-2"></i> Sign
                                      in With Wallet
                                    </button>
                                  );
                                }

                                if (chain.unsupported) {
                                  return (
                                    <button
                                      onClick={openChainModal}
                                      type="button"
                                    >
                                      Wrong network
                                    </button>
                                  );
                                }
                                return (
                                  <div style={{ display: "flex", gap: 12 }}>
                                    <button
                                      onClick={openChainModal}
                                      style={{
                                        display: "flex",
                                        alignItems: "center"
                                      }}
                                      type="button"
                                    >
                                      {chain.hasIcon && (
                                        <div
                                          style={{
                                            background: chain.iconBackground,
                                            width: 12,
                                            height: 12,
                                            borderRadius: 999,
                                            overflow: "hidden",
                                            marginRight: 4
                                          }}
                                        >
                                          {chain.iconUrl && (
                                            <img
                                              alt={chain.name ?? "Chain icon"}
                                              src={chain.iconUrl}
                                              style={{ width: 12, height: 12 }}
                                            />
                                          )}
                                        </div>
                                      )}
                                      {chain.name}
                                    </button>

                                    <button
                                      onClick={openAccountModal}
                                      type="button"
                                    >
                                      {account.displayName}
                                      {account.displayBalance
                                        ? ` (${account.displayBalance})`
                                        : ""}
                                    </button>
                                  </div>
                                );
                              })()}
                            </div>
                          );
                        }}
                      </ConnectButton.Custom> */}
                    </div>
                    <div className="text-center mt-2">
                      <span className="text-muted"> Don't have account? </span>
                      <Link className="anchor_link" to="/create">
                        Sign up now
                      </Link>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
