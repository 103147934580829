import React from 'react';
import "./comp1.css"

const Sidebar = () => {
    return (
        <div id="div-docs">
            <div id="div-docs" class="toc-wrapper" >
                <a href='/'>
                    <img src="/img/BitPlug-0.png" className='m-2' style={{height:"30px", width:"100px"}}/>
                </a>
            <ul id='toc' className='toc-list-h1'>
                <li>
                <a id="ancor" href="#public-rest-api-for-BitPlug" class="toc-h1 toc-link  " data-title="Public Rest API for BitPlug">Public Rest API for BitPlug</a>
                <ul id="ul-docs"className='toc-list-h2 active' style={{display:"block"}}>
                    <li>
                    <a id="ancor" href="#introduction" className="toc-h2 toc-link" data-title="Introduction">Introduction</a>
                    </li>
                    <li><a id="ancor" href="#api-key-setup" class="toc-h2 toc-link" data-title="API Key Setup">API Key Setup</a></li>
                    <li><a id="ancor" href="#api-key-restrictions" class="toc-h2 toc-link" data-title="API Key Restrictions">API Key Restrictions</a></li>
                    <li><a id="ancor" href="#contact-us" class="toc-h2 toc-link" data-title="Contact Us">Contact Us</a></li>
                    <li><a id="ancor" href="#general-api-information" class="toc-h2 toc-link" data-title="General API Information">General API Information</a></li>
                    <li><a id="ancor" href="#http-return-codes" class="toc-h2 toc-link" data-title="HTTP Return Codes">HTTP Return Codes</a></li>
                    <li><a id="ancor" href="#error-codes-and-messages" class="toc-h2 toc-link" data-title="Error Codes and Messages">Error Codes and Messages</a></li>
                    <li><a id="ancor" href="#general-information-on-endpoints" class="toc-h2 toc-link" data-title="General Information on Endpoints">General Information on Endpoints</a></li>
                    <li><a id="ancor" href="#limits" class="toc-h2 toc-link" data-title="Limits">Limits</a></li>
                </ul>
                </li>
                <li><a id="ancor" href="#public-api-endpoints" class="toc-h1 toc-link" data-title="Public API Endpoints">Public (ideal) API Endpoints</a></li>
            </ul>
        </div>
        </div>
    );
};

export default Sidebar;