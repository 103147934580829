import React, { useState, useEffect } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import { isName } from "./redux/helpers/form-validator.functions";
import { submitKyc } from "./redux/actions/authActions";

import FullLoader from "./components/FullLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  N_checkKYCStatus,
  N_getCity,
  N_getCountry,
  N_getState,
} from "./redux/helpers/api_functions_new";

export default function Welcome(props) {
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = useState();
  const [fullloading, setFullLoader] = useState(true);
  const [countryData, setCountry] = useState();
  const [stateData, setState] = useState();
  const [cityData, setCity] = useState();
  const [type_of_kyc, setTypeofKyc] = useState("Individual");
  const [fname, setFname] = useState();
  const [mname, setMname] = useState();
  const [lname, setLname] = useState();
  const [dob, setDob] = useState();
  const [address, setAddress] = useState();
  const [pincode, setPincode] = useState();
  const [visibl, setVisiblity] = useState("h");
  if (!isLoggedIn) props.history?.replace("/login");
  else if (user.kyc === 1) props.history?.replace("/exchage/btc-inrx");
  const backKyc = () => {
    props.history.push("/exchange/btc-inrx");
  };

  const comp_btn = (event) => {
    const kyc_btn = event.target.parentElement;
    kyc_btn.className = "form-group h";
    setVisiblity("vis");
  };
  function changeCountry(event) {
    N_getState(
      event.target.value,
      user?.params ? user.params.token : user.token
    )
      .then((res) => {
        if (res.status === 200) {
          // console.log("resofcountry: ",res);
          // setCity();
          setState(res.params.country_data);
          changeState("country");
        }
      })
      .catch((e) => {
        console.log("con erer:", e);
      });
  }

  function changeState(event) {
    if (event == "country") {
      N_getCity(
        document.getElementById("country").value,
        document.getElementById("state").value,
        user?.params ? user.params.user_id : user.user_id
      )
        .then((res) => {
          if (res.status === 200) {
            // console.log("resofcountry: ",res);
            setCity(res.params.country_data);
          }
        })
        .catch((e) => {
          console.log("con erer:", e);
        });
    } else {
      N_getCity(
        document.getElementById("country").value,
        event.target.value,
        user?.params ? user.params.user_id : user.user_id
      )
        .then((res) => {
          if (res.status === 200) {
            // console.log("resofcountry: ",res);
            setCity(res.params.country_data);
          }
        })
        .catch((e) => {
          console.log("con erer:", e);
        });
    }
  }

  useEffect(() => {
    N_checkKYCStatus(user?.params ? user.params.token : user.token).then(
      (d) => {
        if (d.status === 200) {
          if (d.params.kyc_status === 1)
            // console.log("kst; ", d);
            props.history?.replace("/exchange/btc-inrx");
        }
        setFullLoader(false);
      }
    );
    N_getCountry(user?.params ? user.params.token : user.token)
      .then((res) => {
        if (res.status === 200) {
          // console.log("resofcountry: ",res);
          setCountry(res.params.country_data);
        }
      })
      .catch((e) => {
        console.log("con erer:", e);
      });

    N_getState("India", user?.params ? user.params.token : user.token)
      .then((res) => {
        if (res.status === 200) {
          // console.log("resofstate: ",res);
          setState(res.params.country_data);
        }
      })
      .catch((e) => {
        console.log("erer:", e);
      });
    N_getCity(
      "India",
      "Andaman and Nicobar Islands",
      user?.params ? user.params.token : user.token
    )
      .then((res) => {
        if (res.status === 200) {
          // console.log("resofcity: ",res);
          setCity(res.params.country_data);
        }
      })
      .catch((e) => {
        console.log("erer:", e);
      });
  }, []);

  const submit = (e) => {
    e.preventDefault();
    const body = {
      type_of_kyc: type_of_kyc,
      fname: fname ? fname : "",
      mname: mname ? mname : "",
      lname: lname ? lname : "",
      dob: dob ? dob : "",
      address: address,
      country: document.getElementById("country").value,
      state: document.getElementById("state").value,
      city: document.getElementById("city").value,
      pincode: pincode ? pincode : "",
    };
    const token = user?.params ? user.params.token : user.token;
    setLoading(true);
    dispatch(
      submitKyc(
        body,
        token,
        () => {
          setLoading(false);
          props.history?.replace("/kyc");
        },
        () => setLoading(false)
      )
    );
  };
  if (fullloading)
    return (
      <>
        <Header {...props} />
        <FullLoader />
      </>
    );
  else
    return (
      <>
        <Header {...props} />
        <ProfileSidebar {...props} />
        <div className="main-content">
          <div className="mt-3">
            <h4 className="mb-3">Referral</h4>
            <div className="card">
              <div className="card-body">
                {/* <div className="security_header shead-bg">
            <div className="security_head">
              <div className="sec_circle bg_check_circle shead-circle">
                <i className="fas fa-check-circle"></i>
                <span color="#ffffff" className="email_span">
                  Email
                </span>
              </div>
              <div className="sec_circle bg_check_circle shead-circle">
                <i className="fas fa-check-circle"></i>
                <span color="#ffffff" className="email_span">
                  Security
                </span>
              </div>
              <div className="sec_circle bg_circle shead-circle">
                <i className="fas fa-dot-circle"></i>
                <span color="#ffffff" className="email_span">
                  Welcome
                </span>
              </div>
            </div>
          </div> */}

                <div className="row">
                  <div className="col-12 col-lg-6">
                    <form
                      className="signupform"
                      onSubmit={submit}
                      method="post"
                      id="welcome_form"
                    >
                      <div className="form-group">
                        <small id="msg" style={{ fontSize: "15px" }}>
                          Error message
                        </small>
                      </div>

                      <div className="form-group">
                        <label htmlFor="type_of_kyc">Type of KYC</label>
                        <select
                          id="type_of_kyc"
                          className="form-control"
                          data-role="select-dropdown"
                          onChange={(e) => {
                            setTypeofKyc(e.target.value);
                          }}
                        >
                          <option value="Individual">Individual</option>
                          <option value="Corporate">Corporate</option>
                        </select>
                      </div>

                      <div className="form-group" id="kyc_btn">
                        <div className="row mb-3">
                          <div className="col-lg-6 fs-14">
                            <ul class="list-group mt-4">
                              <li class="list-group-item"> Without KYC</li>
                              <li class="list-group-item">
                                {" "}
                                <span className="badge bg-light border rounded-pill me-2 fs-14">
                                  <span class="fa fa-circle-check text-green me-2"></span>{" "}
                                  Deposit
                                </span>
                              </li>
                              <li class="list-group-item">
                                {" "}
                                <span className="badge bg-light border rounded-pill me-2 fs-14">
                                  <span class="fa fa-circle-check text-green me-2"></span>{" "}
                                  Trade
                                </span>
                              </li>
                              <li class="list-group-item">
                                {" "}
                                <span className="badge bg-light border rounded-pill me-2 fs-14">
                                  <span class="fa-solid fa-circle-xmark text-secondary  me-2"></span>{" "}
                                  Withdrawal
                                </span>
                              </li>
                              <li class="list-group-item">
                                {" "}
                                <span className="badge bg-light border rounded-pill me-2 fs-14">
                                  <span class="fa-solid fa-circle-xmark text-secondary  me-2"></span>{" "}
                                  P2P
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-6">
                            <ul class="list-group mt-4">
                              <li class="list-group-item"> With KYC</li>
                              <li class="list-group-item">
                                {" "}
                                <span className="badge bg-light border rounded-pill me-2 fs-14">
                                  <span class="fa fa-circle-check text-green me-2"></span>{" "}
                                  Deposit
                                </span>
                              </li>
                              <li class="list-group-item">
                                {" "}
                                <span className="badge bg-light border rounded-pill me-2 fs-14">
                                  <span class="fa fa-circle-check text-green me-2"></span>{" "}
                                  Trade
                                </span>
                              </li>
                              <li class="list-group-item">
                                {" "}
                                <span className="badge bg-light border rounded-pill me-2 fs-14">
                                  <span class="fa fa-circle-check text-green me-2"></span>{" "}
                                  Withdrawal
                                </span>
                              </li>
                              <li class="list-group-item">
                                {" "}
                                <span className="badge bg-light border rounded-pill me-2 fs-14">
                                  <span class="fa fa-circle-check text-green me-2"></span>{" "}
                                  P2P
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>{" "}
                        <button
                          type="button"
                          className="btn border-0 text-dark"
                          onClick={() => {
                            backKyc();
                          }}
                        >
                          Skip
                        </button>
                        <button
                          type="button"
                          id="proceed_btn"
                          className="btn btn-primary ms-2"
                          onClick={(e) => {
                            comp_btn(e);
                          }}
                        >
                          Proceed for KYC process
                        </button>
                      </div>
                      <div id="personal_info" className={visibl}>
                        <div>
                          <h5 className="my-4 text-muted">Personal Details</h5>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="user_fname">
                                First Name
                                <span className="text-red ms-1">*</span>
                              </label>
                              <input
                                type="text"
                                name="user_fname"
                                required="required"
                                onChange={(e) => {
                                  isName(e);
                                  setFname(e.target.value);
                                }}
                                className="form-control"
                                id="user_fname"
                              />
                              <i className="fas fa-check-circle"></i>
                              <i className="fas fa-exclamation-circle"></i>
                              <small>Error message</small>
                            </div>
                            <div className="form-group">
                              <label htmlFor="user_mname">Middle Name</label>
                              <input
                                type="text"
                                name="user_mname"
                                onChange={(e) => {
                                  isName(e);
                                  setMname(e.target.value);
                                }}
                                className="form-control"
                                id="user_mname"
                              />
                              <i className="fas fa-check-circle"></i>
                              <i className="fas fa-exclamation-circle"></i>
                              <small>Error message</small>
                            </div>
                            <div className="form-group">
                              <label htmlFor="user_lname">Last Name</label>
                              <input
                                type="text"
                                name="user_lname"
                                onChange={(e) => {
                                  isName(e);
                                  setLname(e.target.value);
                                }}
                                className="form-control"
                                id="user_lname"
                              />
                              <i className="fas fa-check-circle"></i>
                              <i className="fas fa-exclamation-circle"></i>
                              <small>Error message</small>
                            </div>
                            <div className="form-group">
                              <label htmlFor="user_dob">
                                Date Of Birth{" "}
                                <span className="text-red ms-1">*</span>
                              </label>
                              <input
                                type="date"
                                name="user_dob"
                                id="user_dob"
                                required="required"
                                onChange={(e) => {
                                  setDob(e.target.value);
                                }}
                                className="form-control"
                                placeholder="DD-MM-YYYY"
                              />
                              <i className="fas fa-check-circle"></i>
                              <i className="fas fa-exclamation-circle"></i>
                              <small>Error message</small>
                            </div>
                            <div className="form-group">
                              <label htmlFor="user_address">
                                Address<span className="text-red ms-1">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="user_address"
                                required="required"
                                rows="1"
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="country">
                                Country<span className="text-red ms-1">*</span>
                              </label>
                              <select
                                id="country"
                                className="form-control"
                                onChange={(e) => {
                                  changeCountry(e);
                                }}
                                data-role="select-dropdown"
                              >
                                {countryData
                                  ? countryData.map((country) => (
                                      <option
                                        value={country}
                                        selected={
                                          country == "India" ? "selected" : ""
                                        }
                                      >
                                        {country}
                                      </option>
                                    ))
                                  : ""}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="state">
                                State<span className="text-red ms-1">*</span>
                              </label>
                              <select
                                id="state"
                                className="form-control"
                                onChange={(e) => {
                                  changeState(e);
                                }}
                                data-role="select-dropdown"
                              >
                                {stateData
                                  ? stateData.map((state) => (
                                      <option value={state}>{state}</option>
                                    ))
                                  : ""}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="country">
                                City<span className="text-red ms-1">*</span>
                              </label>
                              <select
                                id="city"
                                className="form-control"
                                data-role="select-dropdown"
                              >
                                {cityData
                                  ? cityData?.map((city) => (
                                      <option value={city}>{city}</option>
                                    ))
                                  : ""}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="user_pincode">
                                Pincode<span className="text-red ms-1">*</span>
                              </label>
                              <input
                                type="text"
                                name="user_pincode"
                                required="required"
                                className="form-control"
                                id="user_pincode"
                                onChange={(e) => {
                                  setPincode(e.target.value);
                                }}
                              />
                              <i className="fas fa-check-circle"></i>
                              <i className="fas fa-exclamation-circle"></i>
                              <small>Error message</small>
                            </div>
                            <div className="d-grid">
                              <label htmlFor="submit button "> &nbsp;</label>
                              <input
                                type="hidden"
                                name="session_id"
                                id="session_id"
                              />
                              <button
                                type="submit"
                                id="btn_submit"
                                className="btn btn-primary mt-1"
                              >
                                {loading ? (
                                  <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                                ) : null}
                                <span id="reg">Submit</span>
                              </button>
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
