import React from "react";
import { NavLink } from "react-router-dom";
import "./sidebar.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FiPieChart } from "react-icons/fi";
import {
  RiExchangeFundsFill,
  RiFundsBoxLine,
  RiHistoryFill,
} from "react-icons/ri";
import { TbWorldDownload, TbWorldUpload } from "react-icons/tb";
import { FaBalanceScaleLeft, FaHandHoldingUsd } from "react-icons/fa";
import { BiBot } from "react-icons/bi";
import { AiOutlineGift } from "react-icons/ai";
import { MdOutlineAutoGraph } from "react-icons/md";
import { HiBarsArrowDown } from "react-icons/hi2";

export default function ProfileSidebar(props) {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  if (!isLoggedIn) props.history?.replace("/login");
  return (
    <>
      <a class="s-sidebar__trigger" href="#0">
        <span>
          <HiBarsArrowDown className="fs-2 text-pink" /> All Menu{" "}
        </span>
        {/* <i className="fa fa-bars"></i> */}
      </a>

      <nav class="s-sidebar__nav">
        <ul>
          <li>
            <NavLink className="s-sidebar__nav-link" to="/overview">
              <FiPieChart className="fs-4" /> <span className="">Overview</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="s-sidebar__nav-link" to="/fundingMain">
              <RiFundsBoxLine className="fs-4" />
              <span className="">Funding Main</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink className="s-sidebar__nav-link" to="/AssetsTradingAccount">
            <RiExchangeFundsFill className="fs-4"/> <span className="">Trading</span>
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink className="s-sidebar__nav-link" to="/AssetsMargin">
            <FaBalanceScaleLeft className="fs-4"/>
              <span className=""> Margin</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="s-sidebar__nav-link" to="/AssetsFuture">
            <MdOutlineAutoGraph className="fs-4"/>
              <span className=""> Futures</span>
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink className="s-sidebar__nav-link" to="/TradingBot">
             <BiBot className="fs-4"/>
              <span className=""> Trading Bot</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="s-sidebar__nav-link" to="/AssetsFinance">
              <FaHandHoldingUsd className="fs-4"/>
              <span className=""> Finance</span>
            </NavLink>
          </li> */}
          <li className="border-bottom my-3"></li>

          <li>
            <NavLink className="s-sidebar__nav-link" to="/AssetsDeposit">
              <TbWorldDownload className="fs-4" />
              <span className=""> Deposit</span>
            </NavLink>
          </li>

          <li>
            <NavLink className="s-sidebar__nav-link" to="/AssetsWithdraw">
              <TbWorldUpload className="fs-4" />
              <span className="">Withdraw</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="s-sidebar__nav-link" to="/AssetsHistory">
              <RiHistoryFill className="fs-4" />{" "}
              <span className="">
                History
                <div className="text-muted fs-12">(History & Withdrawal)</div>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink className="s-sidebar__nav-link" to="/MyBonus">
              <AiOutlineGift className="fs-4" />
              <span className="">My Bonuses</span>
            </NavLink>
          </li>
        </ul>
      </nav>

      {/*=========== Modal of deposit menu==================== */}

      {/* <div id="deposit_menu_modal" class="modal fade" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0">
              <h4 class="modal-title">Deposit</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <label className="text-muted fs-16">Select Deposit Type:</label>
              <a href="/AssetsDeposit" className="text-dark">
                <div class="modal_select_options">
                  <img
                    src="/img/deposit-crypto.png"
                    alt="coin symbole"
                    class="deposit_imgs"
                  />
                  <div class="content_div">
                    <div class="mb-0 fw-bold">Deposit Crypto</div>
                    <div class="text-muted fs-14">
                      {" "}
                      I want to deposit exiting crypto assets into my BitPlug
                      account.
                    </div>
                  </div>{" "}
                  <span>
                    <i class="fa-solid fa-arrow-right-long ms-1"></i>
                  </span>
                </div>
              </a>
              <a href="/AssetsFiat" className="text-dark">
                <div class="modal_select_options">
                  <img
                    src="/img/deposit-fiat.png"
                    alt="coin symbole"
                    class="deposit_imgs"
                  />
                  <div class="content_div">
                    <div class="mb-0 fw-bold">Deposit Fiat</div>
                    <div class="text-muted fs-14">
                      {" "}
                      I want to deposit my fiat assets into my BitPlug account
                      (supported payment methods: bank card, bank transfer, and
                      more).
                    </div>
                  </div>{" "}
                  <span>
                    <i class="close fa-solid fa-arrow-right-long ms-1"></i>
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
