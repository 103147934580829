import React from "react";
import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";
export default function LoginORSignup(props) {
  // const { webData } = useSelector((state) => state.websiteDBReducer);
  return (
    <div className="login_register">
      <Link to="/login" className="me-1">Login</Link> Or
      <Link to="/create" className="ms-1">
        Create an Account
      </Link>
    </div>
  );
}
