import React from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";

export default function StartTrade() {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  AOS.init({ duration: 1000 });
  return (
    <>
      <div className="">
        <div className="tree_bg">
        <div className="bg_camel">
          <div className="container">
            <div className="col-md-12">
              <h2 className="para_heading text-center"> Start Your Crypto Journey Now!</h2>
              <h5 className="text-center text-lgray mt-3"> With the <span className="text-pink">BitPlug </span> app and website, trading has never been easier.</h5>
              <div className="text-center mt-5">
                    {" "}
                    {!isLoggedIn ? (
                      <>
                        <a
                          className="btn login_btn px-4 rounded-pill"
                          to="/create"
                        >
                          Sign Up Now
                        </a>
                      </>
                    ) : (
                      <a
                        className="btn btn login_btn px-4 rounded-pill"
                        href="/exchange/rbc-inr"
                      >
                      Trade Now
                      </a>
                    )}
                  </div>
            </div>
            
            {/* <div className="row d-flex align-center justify-content-center">
              <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center">
                <div class="wrapper">
               
                  <a href="https://twitter.com/BitPluglab" target="_blank">
                    <div class="bg-ico" id="twitter">
                      <i class="fab fa-twitter twitter fa-3x"></i>
                    </div>
                  </a>
                  <a href="https://t.me/BitPluglab" target="_blank">
                    <div class="bg-ico" id="telegram">
                      <i class="fab fa-telegram telegram fa-3x"></i>
                    </div>
                  </a>
                  <a href="https://linkedin.com/company/BitPluglab" target="_blank">
                    <div class="bg-ico" id="linkedin">
                      <i class="fab fa-linkedin fa-3x"></i>
                    </div>
                  </a>
                
                </div>
              </div>
            </div> */}
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
