import React, { useEffect } from "react";
import ProfileSidebar from "./components/ProfileSidebar";
import Header from "./HomeComp/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  setFavCurrency,
  setFavCurrencyRate
} from "./redux/actions/coinDBAction";
import Loader from "./components/Loader";
export default function CurrencyPreference(props) {
  const [loading, setLoading] = React.useState(true);
  const [currency, setCurrency] = React.useState([]);
  const { user } = useSelector((state) => state.AuthReducer);
  let { paired_curency } = useSelector((state) => state.coinDBReducer);
  const activeCurrency = "INRX";
  return (
    <>
      <Header {...props} />

      <ProfileSidebar {...props} />

      <div className="main-content">
        <h4 className="mb-3 mt-3">Currency Preference</h4>

        <div className="card">
          <div className="card-header">
            {" "}
            Select your preferred display currency for all markets{" "}
          </div>
          <div className="card-body">
            {!loading ? (
              <article>
                {paired_curency?.map((d, index) => {
                  return (
                    <CurrencyCard
                      name={d.currency_name}
                      symbol={d.currency_coin}
                      icon={d.currency_logo}
                      key={index}
                      active={activeCurrency === d.currency_coin}
                    />
                  );
                })}
              </article>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function CurrencyCard(props) {
  const dispatch = useDispatch();
  const { user_fav_currency, coins } = useSelector(
    (state) => state.coinDBReducer
  );
  return (
    <div className="form-check form-check hero_div">
      <input
        type="radio"
        name="currency"
        id="curreny"
        checked={props.symbol === user_fav_currency.toUpperCase()}
        onChange={() => {
          dispatch(
            setFavCurrency(props.symbol, () => {
              dispatch(
                setFavCurrencyRate(
                  coins[props.symbol.toUpperCase() + "INRX"] !== undefined
                    ? coins[props.symbol.toUpperCase() + "INRX"].current_price
                    : 1,
                  () => {}
                )
              );
            })
          );
        }}
      />
      <label for="">
        <span className="m-2 fs-14">
          <img src={`${props.icon}`} height="25" width="25" alt="" />
          <span className="ms-2">
            {props.name} ({props.symbol})
          </span>
        </span>
      </label>
    </div>
  );
}
