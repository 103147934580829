import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import { GiWallet } from "react-icons/gi";
import { getUserBalance, getUserOrder } from "../redux/actions/coinDBAction";
import {
  N_createBuyOffer,
  N_createSellOffer,
  N_executeOrder,
} from "../redux/helpers/api_functions_new";

import { mul, round } from "../redux/helpers/Math";
import { getRound } from "../redux/helpers/helper_functions";

export default function BuyNSell(props) {
  const { isLoggedIn, user } = useSelector((state) => state.AuthReducer);
  const priceAt = useSelector((state) => state.priceAt);
  const {
    coins,
    wallet,
    paired_curency_price,
    buymarket,
    sellmarket,
    currency_type,
    campare_currency,
  } = useSelector((state) => state.coinDBReducer);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [bloading, setbLoading] = useState(false);
  const [atPrice, setATPrice] = useState(0);
  const [atsPrice, setATSPrice] = useState(0);
  const [cprice, setCprice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [asmount, setSAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [stotal, setSTotal] = useState(0);
  const [stopLimit, setStoplimit] = useState(0);
  const [wallet_details, setWalletDetails] = useState([]);
  const [buybalance, setbuybalance] = useState(0);
  const [sellbalance, setsellbalance] = useState(0);
  const [makerFee, setMakerFee] = useState();
  const [TakerFee, setTakerFee] = useState();
  const coin = props?.match?.params?.id
    ? props.match.params.id.split("-")
    : currency_type + "-" + campare_currency.split("-");
  const [price, setprice] = useState(0);
  let time = 0;

  useEffect(() => {
    if (coins.taker_fees && coins.maker_fees) {
      setMakerFee(0.2);
      setTakerFee(0.5);
    }
  }, []);

  // useEffect(() => {
  //   // console.log(amount * atsPrice,stotal)
  //   setSTotal(round(asmount * atPrice));
  // }, [atPrice, amount, total]);

  // useEffect(() => {
  //   // console.log(asmount * atsPrice,stotal)
  //   setSTotal(round(asmount * atsPrice));
  // }, [atsPrice, asmount, stotal]);

  function setCurrentBuySellTokenBalance() {
    wallet_details.map((item, i) => {
      if (item.symbol === coin[1].toUpperCase()) {
        setbuybalance(getRound(item.avl_balance));
      } else if (item.symbol === coin[0].toUpperCase()) {
        setsellbalance(getRound(item.avl_balance));
      }
    });
  }

  function getCurrentBuySellTokenBalance() {
    let final_data =
      wallet &&
      Object.keys(wallet).map((res, i) => {
        return {
          id: wallet[res]?.id,
          icon: wallet[res]?.icon,
          symbol: wallet[res]?.symbol.toUpperCase(),
          name: wallet[res]?.name,
          status: wallet[res]?.status,
          withdral_fee: wallet[res]?.withdrawal_fee,
          locked: getRound(wallet[res]?.locked),
          address: wallet[res]?.wallet_address,
          balance: getRound(wallet[res]?.balance),
          avl_balance: getRound(wallet[res]?.balance - wallet[res]?.locked),
        };
      });
    setWalletDetails(final_data);
    // console.log("fdata: ", final_data);
    setTimeout(() => setLoading(false), 800);
  }

  useEffect(() => {
    setCurrentBuySellTokenBalance();
  }, [coin, wallet_details]);

  useEffect(() => {
    Object.values(coins)?.map((d, i) => {
      if (d.symbol === coin[0].toUpperCase()) {
        setprice(
          Number(d.current_price) /
            Number(
              paired_curency_price
                ? paired_curency_price[coin[1].toUpperCase()]
                  ? paired_curency_price[coin[1].toUpperCase()]
                  : 1
                : 1
            )
        );
      }
    });
  }, [coins, coin]);

  useEffect(() => {
    if (price && time == 0) {
      // setATPrice(getRound(price));
      // setATSPrice(getRound(price));
      setCprice(getRound(price));
      time = 1;
    }
  }, [price, wallet]);

  useEffect(() => {
    if (isLoggedIn && user?.params ? user.params.token : user.token) {
      getCurrentBuySellTokenBalance();
    }
  }, [wallet]);

  useEffect(() => {
    const mprice = round(buymarket.marketprice);
    const mvolume = round(buymarket.marketvolume);
    const totalinr = mul(mvolume, mprice);
    setATSPrice(mprice);
    setSAmount(mvolume);
    setSTotal(totalinr);
  }, [buymarket]);

  useEffect(() => {
    const mprice = round(sellmarket.marketprice);
    const mvolume = round(sellmarket.marketvolume);
    const totalinr = mul(mvolume, mprice);
    setATPrice(mprice);
    setAmount(mvolume);
    setTotal(totalinr);
  }, [sellmarket]);

  function buyCoin(atPrice, amount, c, cp) {
    setbLoading(true);
    N_createBuyOffer(
      atPrice,
      amount,
      c,
      cp,
      user?.params ? user.params.token : user.token,
      cprice
    )
      .then((d) => {
        if (d.status == 200) {
          setATPrice(0);
          setAmount(0);
          setTotal(0);
          NotificationManager.success(d.message);
          getCurrentBuySellTokenBalance();
          dispatch(
            getUserBalance(user?.params ? user.params.token : user.token)
          );
          dispatch(getUserOrder(user?.params ? user.params.token : user.token));
          N_executeOrder(
            d.result.order_id,
            user?.params ? user.params.token : user.token,
            d.result.type
          ).then((d) => {
            if (d.status == 200) {
              getCurrentBuySellTokenBalance();
              dispatch(
                getUserBalance(user?.params ? user.params.token : user.token)
              );
              dispatch(
                getUserOrder(user?.params ? user.params.token : user.token)
              );
            }
          });
        } else if (d.status == 400) {
          getCurrentBuySellTokenBalance();
          dispatch(
            getUserBalance(user?.params ? user.params.token : user.token)
          );
          dispatch(getUserOrder(user?.params ? user.params.token : user.token));
          NotificationManager.success(d.message);
        }
        setbLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function sellCoin(atPrice, amount, c, cp) {
    setLoading(true);
    N_createSellOffer(
      atPrice,
      amount,
      c,
      cp,
      user?.params ? user.params.token : user.token,
      cprice
    )
      .then((d) => {
        if (d.status == 200) {
          setATSPrice(0);
          setSAmount(0);
          setSTotal(0);
          NotificationManager.success(d.message);
          getCurrentBuySellTokenBalance();
          dispatch(
            getUserBalance(user?.params ? user.params.token : user.token)
          );
          dispatch(getUserOrder(user?.params ? user.params.token : user.token));
          N_executeOrder(
            d.result.order_id,
            user?.params ? user.params.token : user.token,
            d.result.type
          ).then((d) => {
            if (d.status == 200) {
              getCurrentBuySellTokenBalance();
              dispatch(
                getUserBalance(user?.params ? user.params.token : user.token)
              );
              dispatch(
                getUserOrder(user?.params ? user.params.token : user.token)
              );
            }
          });
        } else if (d.status == 400) {
          getCurrentBuySellTokenBalance();
          dispatch(
            getUserBalance(user?.params ? user.params.token : user.token)
          );
          dispatch(getUserOrder(user?.params ? user.params.token : user.token));
          NotificationManager.success(d.message);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <>
      <div className="ps-1 py-0 mt-2">
        <div className="card">
          <div className="card-header">Stop Limit</div>

          <div className="card-body order_height" id="open-order">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <select
                    className="custom-select bg-light text-secondary border buy-sell-form-bg buy-sell-theme d-none"
                    value={stopLimit}
                    onChange={(e) =>
                      setStoplimit(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                      )
                    }
                  >
                    <option value={0}>Limit</option>
                    <option value={1}>Stop Limit</option>
                  </select>
                </div>
                <div className="">
                  {stopLimit === 1 ? (
                    <div className="input-group exchange_input_grp mb-2">
                      <span className="input-group-text">STOP PRICE</span>

                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        value="0"
                      />
                      <span className="input-group-text">INR</span>
                    </div>
                  ) : null}

                  <div className="input-group mb-3 exchange_input_grp">
                    <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                      Price
                    </span>

                    <input
                      type="text"
                      className="form-control buy-sell-form-bg buy-sell-theme"
                      value={atPrice}
                      onChange={(e) => {
                        setATPrice(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1")
                        );
                        setTotal(
                          getRound(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1") * amount)
                        );
                      }}
                    />
                    <span className="input-group-text bg-transparent">
                      {coin[1].toUpperCase()}
                    </span>
                  </div>
                  <div className="input-group mb-3 exchange_input_grp">
                    <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                      Amount
                    </span>

                    <input
                      type="text"
                      className="form-control buy-sell-form-bg buy-sell-theme"
                      value={amount}
                      onChange={(e) => {
                        if(atPrice>0) {
                          setAmount(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          );
                          setTotal(
                            getRound(e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1") * atPrice)
                          );
                        }
                      }}
                    />

                    <span className="input-group-text">
                      {coin[0].toUpperCase()}
                    </span>
                  </div>
                  <div className="input-group mb-3 exchange_input_grp">
                    <span className="input-group-text buy-sell-form-bg buy-sell-theme ">
                      Total
                    </span>

                    <input
                      type="text"
                      className="form-control buy-sell-form-bg buy-sell-theme "
                      value={total}
                      onChange={(e) => {
                        if(atPrice>0) {
                          setAmount(
                            getRound(e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1") / atPrice)
                          );
                          setTotal(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          );
                        }
                      }}
                    />

                    <span className="input-group-text">
                      {coin[1].toUpperCase()}
                    </span>
                  </div>

                  {isLoggedIn ? (
                    <>
                      <div className="row mt-2  text-muted">
                        <div className="col-12 d-flex justify-content-between">
                          <div
                            className="buy_sell_amount_picker"
                            onClick={() => {
                              if(atPrice>0) {
                                  setTotal(
                                    getRound(buybalance ? buybalance * 0.25 : 0)
                                  );
                                  setAmount(
                                    getRound(
                                      (buybalance ? buybalance * 0.25 : 0) / atPrice
                                    )
                                  );
                                }
                            }}
                          >
                            25%
                          </div>
                          <div
                            className="buy_sell_amount_picker"
                            onClick={() => {
                              if(atPrice>0) {
                                setTotal(getRound(buybalance ? buybalance * 0.5 : 0));
                                setAmount(
                                  getRound(buybalance ? (buybalance * 0.5) / atPrice : 0)
                                );
                              }
                            }}
                          >
                            50%
                          </div>
                          <div
                            className="buy_sell_amount_picker btn btn-default"
                            onClick={() => {
                              if(atPrice>0) {
                                setTotal( getRound(buybalance ? buybalance * 0.75 : 0));
                                setAmount(
                                  getRound(buybalance ? (buybalance * 0.75) / atPrice : 0)
                                );
                              }
                            }}
                          >
                            75%
                          </div>
                          <div
                            className="buy_sell_amount_picker"
                            onClick={() => {
                              if(atPrice>0) {
                                setTotal(getRound(buybalance ? buybalance : 0));
                                setAmount(getRound(buybalance ? buybalance / atPrice : 0));
                              }
                             
                            }}
                          >
                            100%
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3 text-muted">
                        <div className="col-lg-6">
                          <span className="fs-12">
                            Buy {coin[0].toUpperCase()}
                          </span>
                        </div>
                        <div className="col-lg-6 text-end fs-12">
                          {/* <span className="mx-2" title="wallet">
                            <i className="fa fa-wallet"> </i>
                          </span> */}
                          {coin[1] !== campare_currency
                            ? buybalance + " " + coin[1].toUpperCase()
                            : buybalance + " " + campare_currency.toUpperCase()}
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="d-grid mt-2">
                    <button
                      className="btn btn-success"
                      disabled={bloading}
                      onClick={() => {
                        if (isLoggedIn) {
                          buyCoin(atPrice, amount, coin[0], coin[1]);
                        } else {
                          NotificationManager.error(
                            "First login then perform buy/sell"
                          );
                        }
                      }}
                    >
                      {bloading ? (
                        <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                      ) : null}
                      BUY {coin[0].toUpperCase()}
                    </button>
                  </div>
                  <div className="text-muted small text-center mt-1">
                    {`Fee: Maker fee: ${
                      makerFee ? makerFee + "%" : ""
                    }| Taker fee: ${TakerFee ? TakerFee + "%" : ""}`}
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                {/* sell tab */}

                <div id="order-history">
                  <div className="">
                    <div className="input-group mb-3 exchange_input_grp">
                      <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                        Price
                      </span>

                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        value={atsPrice}
                        onChange={(e) => {
                          setATSPrice(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          );
                          setSTotal(
                            getRound(e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1") * asmount)
                          );
                        }}
                      />
                      <div className="input-group-text">
                        {coin[1].toUpperCase()}
                      </div>
                    </div>
                    <div className="input-group mb-3 exchange_input_grp">
                      <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                        Amount
                      </span>

                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        value={asmount}
                        onChange={(e) => {
                          if(atsPrice>0) {
                            setSAmount(
                              e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*?)\..*/g, "$1")
                            );
                            setSTotal(
                              getRound(e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*?)\..*/g, "$1") * atsPrice)
                            );
                          }
                         
                        }}
                      />
                      <span className="input-group-text">
                        {" "}
                        {coin[0].toUpperCase()}
                      </span>
                    </div>

                    <div className="input-group mb-3 exchange_input_grp">
                      <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                        Total
                      </span>

                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        value={stotal}
                        onChange={(e) => {
                          if(atsPrice>0) {
                            setSAmount(
                              getRound(e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*?)\..*/g, "$1") / atsPrice)
                            );
                            setSTotal(
                              e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*?)\..*/g, "$1")
                            );
                          }
                          
                        }}
                      />
                      <span className="input-group-text">
                        {coin[1].toUpperCase()}
                      </span>
                    </div>
                    {isLoggedIn ? (
                      <>
                        <div className="row">
                          <div className="col-12 d-flex justify-content-between">
                            <span
                              className="buy_sell_amount_picker"
                              onClick={() => {
                                if(atsPrice>0) {
                                  setSAmount(
                                    sellbalance ? getRound(sellbalance * 0.25) : 0
                                  );
                                  setSTotal(
                                    sellbalance
                                      ? getRound(atsPrice * sellbalance * 0.25)
                                      : 0
                                  );
                                }
                               
                              }}
                            >
                              25%
                            </span>
                            <span
                              className="px-1 buy_sell_amount_picker"
                              onClick={() => {
                                if(atsPrice>0) {
                                  setSAmount(sellbalance ? getRound(sellbalance * 0.5) : 0);
                                  setSTotal(
                                    sellbalance ? getRound(atsPrice * sellbalance * 0.5) : 0
                                  );
                                }
                              }}
                            >
                              50%
                            </span>
                            <span
                              className="pl-1 buy_sell_amount_picker"
                              onClick={() => {
                                if(atsPrice>0) {
                                  setSAmount(
                                    sellbalance ? getRound(sellbalance * 0.75) : 0
                                  );
                                  setSTotal(
                                    sellbalance
                                      ? getRound(atsPrice * sellbalance * 0.75)
                                      : 0
                                  );
                                }
                              }}
                            >
                              75%
                            </span>
                            <span
                              className="pl-1 buy_sell_amount_picker"
                              onClick={() => {
                             
                                if(atsPrice>0) {
                                  setSAmount(sellbalance ? sellbalance : 0);
                                  setSTotal(
                                    sellbalance ? getRound(atsPrice * sellbalance) : 0
                                  );
                                }
                              }}
                            >
                              100%
                            </span>
                          </div>
                        </div>
                        <div className="row mt-3 text-muted">
                          <div className="col-lg-6">
                            <span className="fs-12">
                              Sell {coin[0].toUpperCase()}
                            </span>
                          </div>
                          <div className="col-6 text-end fs-12">
                            {/* <span className="me-2" title="wallet">
                            <i className="fa fa-wallet"> </i>
                          </span> */}
                            {sellbalance
                              ? coin[1] !== campare_currency
                                ? sellbalance
                                : sellbalance
                              : 0}{" "}
                            {coin[0].toUpperCase()}
                          </div>{" "}
                        </div>
                      </>
                    ) : null}

                    <div className="d-grid mt-2">
                      <button
                        className="btn btn-pink"
                        disabled={loading}
                        onClick={() => {
                          if (isLoggedIn) {
                            sellCoin(atsPrice, asmount, coin[0], coin[1]);
                          } else {
                            NotificationManager.error(
                              "First login then perform buy/sell"
                            );
                          }
                        }}
                      >
                        {loading ? (
                          <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                        ) : null}
                        SELL {coin[0].toUpperCase()}
                      </button>
                    </div>
                    <div className="text-muted small text-center mt-1">
                      {`Fee: Maker fee: ${
                        makerFee ? makerFee + "%" : ""
                      }| Taker fee: ${TakerFee ? TakerFee + "%" : ""}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
