import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";

export default function MyBonus() {
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="container">
            <div className="marginTop">
              <div className="account_page">
                <div className="page-content-title">
                  <h3 className="">My Bounuses</h3>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="card box_shadow">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <h6 className="fw-bold mb-3">
                              Referral Bonus{" "}
                              <span class="text-lgray ms-2">(2023/07/04)</span>
                            </h6>
                            <div>
                              <a class="anchor_link" href="#">
                                Details
                              </a>{" "}
                            </div>
                          </div>

                          <div className="mb-2">
                            <div class="">
                              <span>
                                ≈ <span className="desc_card">0</span>
                              </span>
                              <span className="text-lgray ms-1">USDT</span>
                            </div>
                          </div>
                          <div>
                            <span class="me-1">Total</span>

                            <span>≈ 0.00</span>
                            <span>USD</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="card box_shadow">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <h6 className="fw-bold mb-3">
                              BitPlug Bonus{" "}
                              <span class="text-lgray ms-2">(2023/07/04)</span>
                            </h6>
                            <div>
                              <a class="anchor_link" href="#">
                                Details
                              </a>{" "}
                            </div>
                          </div>

                          <div className="mb-2">
                            <div class="">
                              <span>
                                ≈ <span className="desc_card">0</span>
                              </span>
                              <span className="text-lgray ms-1">USDT</span>
                            </div>
                          </div>
                          <div>
                            <span class="me-1">Total</span>

                            <span>≈ 0.00</span>
                            <span>USD</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="card box_shadow">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <h6 className="fw-bold mb-3">
                              Other Rewards{" "}
                              <span class="text-lgray ms-2">(2023/07/04)</span>
                            </h6>
                            <div>
                              <a class="anchor_link" href="#">
                                Details
                              </a>{" "}
                            </div>
                          </div>

                          <div className="mb-2">
                            <div class="">
                              <span>
                                ≈ <span className="desc_card">0</span>
                              </span>
                              <span className="text-lgray ms-1">USDT</span>
                            </div>
                          </div>
                          <div>
                            <span class="me-1">Total</span>

                            <span>≈ 0.00</span>
                            <span>USD</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                  <div className="hero_div border-0 mb-3">
                    <div className="row align-items-center">
                      <div className="col-lg-10">
                        <div className="row align-items-center">
                          <div className="col-lg-2 mb-2">
                            <img
                              src="/img/thumb1.png"
                              alt=""
                              className="bonus_img"
                            />
                          </div>
                          <div className="col-lg-10">
                            <h4 className="mb-3">
                              Referral Bonus{" "}
                              <span>
                                <a href="#" className="anchor_link fs-12">
                                  What is Referral Bonus ?
                                </a>
                              </span>
                            </h4>
                            <div className="mb-2">
                              <span className="text-black">
                                Referral Code: QBSY54HE{" "}
                                <i className=" fa fa-copy ms-2"></i>
                              </span>
                            </div>
                            <div>
                              <span className="text-black">
                                Total Invitee: 0 Person
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 mb-2 text-end">
                        <a href="#" className="btn btn-pink">
                          View <i class="fa fa-circle-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="hero_div border-0 mb-3">
                    <div className="row align-items-center">
                      <div className="col-lg-10">
                        <div className="row align-items-center">
                          <div className="col-lg-2 mb-2">
                            <img
                              src="/img/thumb1.png"
                              alt=""
                              className="bonus_img"
                            />
                          </div>
                          <div className="col-lg-10">
                            <h4 className="mb-3">
                              BitPlug Bonus{" "}
                              <span>
                                <a href="#" className="anchor_link fs-12">
                                  What is BitPlug Bonus ?
                                </a>
                              </span>
                            </h4>
                            <div className="mb-2">
                              <span className="text-black">
                                Referral Code: QBSY54HE{" "}
                                <i className=" fa fa-cart ms-2"></i>
                              </span>
                            </div>
                            <div>
                              <span className="text-black">
                                Total Invitee: 0 Person
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 mb-2 text-end">
                        <a href="#" className="btn btn-pink">
                          View <i class="fa fa-circle-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="hero_div border-0 mb-3">
                    <div className="row align-items-center">
                      <div className="col-lg-10">
                        <div className="row align-items-center">
                          <div className="col-lg-2 mb-2">
                            <img
                              src="/img/thumb1.png"
                              alt=""
                              className="bonus_img"
                            />
                          </div>
                          <div className="col-lg-10">
                            <h4 className="mb-3">
                              BitPlug Bonus{" "}
                              <span>
                                <a href="#" className="anchor_link fs-12">
                                  What is BitPlug Bonus ?
                                </a>
                              </span>
                            </h4>
                            <div className="mb-2">
                              <span className="text-black">
                                Referral Code: QBSY54HE{" "}
                                <i className=" fa fa-cart ms-2"></i>
                              </span>
                            </div>
                            <div>
                              <span className="text-black">
                                Total Invitee: 0 Person
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 mb-2 text-end">
                        <a href="#" className="btn btn-pink">
                          View <i class="fa fa-circle-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="hero_div border-0 mb-3">
                    <div className="row align-items-center">
                      <div className="col-lg-10">
                        <div className="row align-items-center">
                          <div className="col-lg-2 mb-2">
                            <img
                              src="/img/thumb1.png"
                              alt=""
                              className="bonus_img"
                            />
                          </div>
                          <div className="col-lg-10">
                            <h4 className="mb-3">
                              BitPlug Bonus{" "}
                              <span>
                                <a href="#" className="anchor_link fs-12">
                                  What is BitPlug Bonus ?
                                </a>
                              </span>
                            </h4>
                            <div className="mb-2">
                              <span className="text-black">
                                Referral Code: QBSY54HE{" "}
                                <i className=" fa fa-cart ms-2"></i>
                              </span>
                            </div>
                            <div>
                              <span className="text-black">
                                Total Invitee: 0 Person
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 mb-2 text-end">
                        <a href="#" className="btn btn-pink">
                          View <i class="fa fa-circle-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 mt-5 mb-5">
                  <h5>Recent Events</h5>

                  <div className="row">
                    <div className="col-lg-4 mb-5">
                      <div className="card">
                        <div class="image-wrapper">
                          <img
                            class="featured-image"
                            src="/img/events-1.jpg"
                            alt="Reecent Events"
                          />
                          <div class="overlay"></div>
                        </div>
                        <div class="event-title">
                          BitPlug Spotlight - MetaJuice (VCORE)
                        </div>
                        <div className="event-info">
                          <span className="gray_badge">Ended</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mb-5">
                      <div className="card">
                        <div class="image-wrapper">
                          <img
                            class="featured-image"
                            src="/img/events-1.jpg"
                            alt="Reecent Events"
                          />
                          <div class="overlay"></div>
                        </div>
                        <div class="event-title">
                          BitPlug Spotlight - MetaJuice (VCORE)
                        </div>
                        <div className="event-info">
                          <span className="gray_badge">Ended</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mb-5">
                      <div className="card">
                        <div class="image-wrapper">
                          <img
                            class="featured-image"
                            src="/img/events-1.jpg"
                            alt="Reecent Events"
                          />
                          <div class="overlay"></div>
                        </div>
                        <div class="event-title">
                          BitPlug Spotlight - MetaJuice (VCORE)
                        </div>
                        <div className="event-info">
                          <span className="gray_badge">Ended</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mb-5">
                      <div className="card">
                        <div class="image-wrapper">
                          <img
                            class="featured-image"
                            src="/img/events-1.jpg"
                            alt="Reecent Events"
                          />
                          <div class="overlay"></div>
                        </div>
                        <div class="event-title">
                          BitPlug Spotlight - MetaJuice (VCORE)
                        </div>
                        <div className="event-info">
                          <span className="gray_badge">Ended</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mb-5">
                      <div className="card">
                        <div class="image-wrapper">
                          <img
                            class="featured-image"
                            src="/img/events-1.jpg"
                            alt="Reecent Events"
                          />
                          <div class="overlay"></div>
                        </div>
                        <div class="event-title">
                          BitPlug Spotlight - MetaJuice (VCORE)
                        </div>
                        <div className="event-info">
                          <span className="gray_badge">Ended</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mb-5">
                      <div className="card">
                        <div class="image-wrapper">
                          <img
                            class="featured-image"
                            src="/img/events-1.jpg"
                            alt="Reecent Events"
                          />
                          <div class="overlay"></div>
                        </div>
                        <div class="event-title">
                          BitPlug Spotlight - MetaJuice (VCORE)
                        </div>
                        <div className="event-info">
                          <span className="gray_badge">Ended</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 text-center mt-3">
                    <a href="#" className=" btn btn-pink">
                      View More <i class="fa fa-circle-arrow-right ms-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
