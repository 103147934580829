import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// import "./header.css";
import { user_logout } from "../redux/actions/authActions";
import { switchTheme } from "../redux/actions/coinDBAction";
// import NotificationBanner from "./BannerData";
import { adminurl } from "../redux/helpers/api_functions_new";
import {
  RiExchangeDollarLine,
  RiExchangeFundsFill,
  RiFundsBoxLine,
  RiHistoryFill,
} from "react-icons/ri";
import {
  FaBalanceScaleLeft,
  FaHandHoldingUsd,
  FaRegDotCircle,
  FaRegEye,
  FaRegEyeSlash,
} from "react-icons/fa";
import { BiBot } from "react-icons/bi";
import { MdOutlineAutoGraph, MdOutlineCurrencyExchange } from "react-icons/md";
import { GoGraph } from "react-icons/go";
import { HiBars3CenterLeft } from "react-icons/hi2";
import { RxCross1 } from "react-icons/rx";
import { TbHistoryToggle } from "react-icons/tb";
import { CiMoneyBill } from "react-icons/ci";
import { AiOutlineUser } from "react-icons/ai";
import { getCoinRate, getRound } from "../redux/helpers/helper_functions";

export default function Header(props) {
  // console.log("login", props?.location?.pathname);
  const [total_inr, setTotalInr] = useState(0);
  const [total_usdt, setTotalUsdt] = useState(0);
  const dispatch = useDispatch();
  const { isLoggedIn, switch_theme, user, profile } = useSelector(
    (state) => state.AuthReducer
  );
  const {
    wallet,
    paired_curency_price,
    coins,
    wallet_loading,
    currency_type,
    campare_currency,
  } = useSelector((state) => state.coinDBReducer);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  async function backRouter() {
    dispatch(
      user_logout(() => {
        props.history?.replace("/login");
      })
    );
    document.location.reload();
  }
  async function swtchTheme() {
    if (switch_theme == "dark") {
      dispatch(switchTheme("light"));
      localStorage.removeItem("tradingview.current_theme.name");
      localStorage.setItem("exchange_inrx_theme", "light");
      const body = document.getElementsByTagName("body")[0];
      body?.classList.add("is_light");
      body?.classList.remove("is_dark");

      var icon = document.querySelector("#theme_icon");
      icon?.classList.add("fa-sun");
      icon?.classList.remove("fa-moon");
    } else {
      dispatch(switchTheme("dark"));
      localStorage.removeItem("tradingview.current_theme.name");
      localStorage.setItem("exchange_inrx_theme", "dark");
      const body = document.getElementsByTagName("body")[0];
      body?.classList.add("is_dark");
      body?.classList.remove("is_light");

      var icon = document.querySelector("#theme_icon");

      icon?.classList.add("fa-moon");
      icon?.classList.remove("fa-sun");
    }
  }

  function toggle() {
    document.getElementById("offcanvas").classList.toggle("show");
  }
  useEffect(() => {
    if (!wallet_loading) {
      getWallets();
    }
  }, [wallet, coins, paired_curency_price]);

  function getWallets() {
    let total = 0;
    wallet &&
      Object.keys(wallet).map((res, i) => {
        let wallet_type = wallet[res]?.symbol.toUpperCase();
        let rate = getCoinRate(coins, wallet_type);
        let inr_val = rate * getRound(wallet[res]?.balance);
        total = total + parseFloat(inr_val);
      });
    setTotalInr(getRound(total));
    setTotalUsdt(getRound(total / paired_curency_price.USDT));
  }

  return (
    <>
      {/* <NotificationBanner /> */}

      <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light fixed-top py-3 home_nav">
          <Link className="navbar-brand" to="/">
            {webData.logo_img_name ? (
              <img
                className="ms-2"
                src={`${adminurl}${webData?.logo_img_name}`}
                alt="logo"
                width="100"
              />
            ) : (
              <span>
                <div className="skeleton logo ms-2"></div>
              </span>
            )}
          </Link>{" "}
          {/*============= mobile Menu (Account | Notification| Toggle bar) Start ============= */}
          <div className="d-lg-none">
            {props?.location?.pathname != "/login" &&
            props?.location?.pathname != "/create" ? (
              <div className="d-flex align-items-center gap-1">
                {/* {!isLoggedIn ? (
                  <>
                    <li>
                      <Link to="/exchange/btc-inrx" className="dropdown-item">
                        <div className="dropdown-body-item">
                          <div className="d-flex justify-content-between fs-12 text-lgray">
                            <span>Spot</span>
                            <span>
                              Maker/Taker:{" "}
                              <span className="text-dark">0.08% / 0.08%</span>
                            </span>
                          </div>
                          <div className="d-flex justify-content-between fs-12 text-lgray">
                            <span>Futures</span>
                            <span>
                              Maker/Taker:{" "}
                              <span className="text-dark">0.02% / 0.06%</span>
                            </span>
                          </div>

                          <div className="fs-12 mt-3 pt-2">
                            Pay Fee with KCS (20% off):{" "}
                            <span className="text-green">Enabled</span>
                          </div>
                        </div>
                      </Link>{" "}
                    </li>

                    <li>
                      <Link to="/profile" className="dropdown-item">
                        <div className="dropdown-body-item pt-3 border-top">
                          Trading Fees
                        </div>
                      </Link>
                    </li>

                    <li>
                      <Link to="/AccountSecurity" className="dropdown-item">
                        <div className="dropdown-body-item">
                          Account Security
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/AccountVerification">
                        <div className="dropdown-body-item">
                          {" "}
                          UID :{" "}
                          {user?.params
                            ? user.params.user_id
                            : user.user_id}{" "}
                          <i className="fa fa-copy ms-1"></i>
                        </div>
                      </Link>
                    </li>

                    <li>
                      {" "}
                      <Link to="/exchange/btc-inrx" className="dropdown-item">
                        <div className="dropdown-body-item">
                          <div className="d-flex justify-content-between fs-12 text-lgray">
                            <span>Spot</span>
                            <span>
                              Maker/Taker:{" "}
                              <span className="text-dark">0.08% / 0.08%</span>
                            </span>
                          </div>
                          <div className="d-flex justify-content-between fs-12 text-lgray">
                            <span>Futures</span>
                            <span>
                              Maker/Taker:{" "}
                              <span className="text-dark">0.02% / 0.06%</span>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="/ApiManagement">
                        <div className="dropdown-body-item">API Management</div>
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="/subAccount">
                        <div className="dropdown-body-item"> Sub Account </div>
                      </Link>
                    </li>

                    <li>
                      <a
                        className="dropdown-item cursor_pointer border-top"
                        onClick={() => {
                          backRouter();
                        }}
                      >
                        Sign Up
                      </a>
                    </li>
                  </>
                ) : null} */}

                {isLoggedIn ? (
                  <>
                    <li
                      class="nav-item header-nav-item dropdown"
                      id="user_link"
                    >
                      <a
                        class="nav-link py-0 "
                        data-bs-toggle="offcanvas"
                        href="#offcanvasExample"
                        role="button"
                        aria-controls="offcanvasExample"
                      >
                        {/* <a
                        class="nav-link py-0 dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                        
                      > */}
                        <div class="circle_backdrop_outline gray_border">
                          {profile?.name
                            ? profile?.name
                                ?.split("")
                                .slice(0, 2)
                                .join("")
                                .toUpperCase()
                            : profile?.email
                                ?.split("")
                                .slice(0, 2)
                                .join("")
                                .toUpperCase()}
                        </div>
                      </a>

                      <div
                        class="offcanvas offcanvas-end"
                        tabindex="-1"
                        id="offcanvasExample"
                        aria-labelledby="offcanvasExampleLabel"
                      >
                        <div class="offcanvas-header">
                          <h4
                            class="offcanvas-title"
                            id="offcanvasExampleLabel"
                          >
                            Your Accout
                          </h4>
                          <button
                            type="button"
                            class="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="offcanvas-body">
                          <div className="px-3">
                            <Link to="/profile" className="text-dark">
                              <h5 className="h5 text-truncate ps-1 d-flex gap-2">
                                {" "}
                                <AiOutlineUser />
                                {profile?.name
                                  ? profile.name
                                  : profile?.email}{" "}
                              </h5>
                              <span className="custom_badge gray_badge text-lgray rounded-pill">
                                {" "}
                                UID :{" "}
                                {user?.params
                                  ? user.params.user_id
                                  : user.user_id}{" "}
                                <i className="fa fa-copy ms-1"></i>
                              </span>
                            </Link>
                          </div>
                          <ul class="mt-3 navbar-nav">
                            <li className="nav-item">
                              {" "}
                              <Link to="/exchange/btc-inr" className="nav-link">
                                <div className="dropdown-body-item">
                                  <div className="d-flex justify-content-between text-lgray">
                                    <span>Spot</span>
                                    <span>
                                      Maker/Taker:{" "}
                                      <span className="text-dark">
                                        0.08% / 0.08%
                                      </span>
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between  text-lgray">
                                    <span>Futures</span>
                                    <span>
                                      Maker/Taker:{" "}
                                      <span className="text-dark">
                                        0.02% / 0.06%
                                      </span>
                                    </span>
                                  </div>

                                  {/* <div className="mt-3 pt-2">
                                    Pay Fee with KCS (20% off):{" "}
                                    <span className="text-green">Enabled</span>
                                  </div> */}
                                </div>
                              </Link>{" "}
                            </li>

                            {/* <li className="nav-item">
                              <Link
                                to="/profile"
                                className="nav-item header-nav-item"
                              >
                                <a className="nav-link">Trading Fees</a>
                              </Link>
                            </li> */}

                            <li className="nav-item">
                              <Link to="/AccountSecurity" className="nav-link">
                                <div className="dropdown-body-item">
                                  Account Security
                                </div>
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to="/AccountVerification"
                              >
                                <div className="dropdown-body-item">
                                  {" "}
                                  Identity Verification{" "}
                                  <span className="custom_badge bg-soft-pink">
                                    LV1
                                  </span>
                                </div>
                              </Link>
                            </li>

                            {/* <li className="nav-item">
                              <Link className="nav-link" to="/ApiManagement">
                                <div className="dropdown-body-item">
                                  API Management
                                </div>
                              </Link>
                            </li> */}

                            {/* <li className="nav-item">
                              <Link className="nav-link" to="/subAccount">
                                <div className="dropdown-body-item">
                                  {" "}
                                  Sub Account{" "}
                                </div>
                              </Link>
                            </li> */}

                            <li className="nav-item">
                              <a
                                className="nav-link cursor_pointer"
                                onClick={() => {
                                  backRouter();
                                }}
                              >
                                <div className="dropdown-body-item text-center">
                                  <div className="login_btn"> Log Out</div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* <ul class="dropdown-menu  dropdown-menu-end  pb-0">
                        <li>
                          <Link to="/profile" className="dropdown-item">
                            <div className="dropdown-body-item border-bottom">
                              <h2 className="big_heading mb-2 text-truncate">
                                {profile?.name ? profile.name : profile?.email}
                              </h2>
                              <span className="custom_badge gray_badge text-lgray rounded-pill">
                                {" "}
                                UID :{" "}
                                {user?.params
                                  ? user.params.user_id
                                  : user.user_id}{" "}
                                <i className="fa fa-copy ms-1"></i>
                              </span>
                            </div>
                          </Link>
                        </li>

                        <li>
                          {" "}
                          <Link
                            to="/exchange/btc-inr"
                            className="dropdown-item"
                          >
                            <div className="dropdown-body-item">
                              <div className="d-flex justify-content-between fs-12 text-lgray">
                                <span>Spot</span>
                                <span>
                                  Maker/Taker:{" "}
                                  <span className="text-dark">
                                    0.08% / 0.08%
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex justify-content-between fs-12 text-lgray">
                                <span>Futures</span>
                                <span>
                                  Maker/Taker:{" "}
                                  <span className="text-dark">
                                    0.02% / 0.06%
                                  </span>
                                </span>
                              </div>

                              <div className="fs-12 mt-3 pt-2">
                                Pay Fee with KCS (20% off):{" "}
                                <span className="text-green">Enabled</span>
                              </div>
                            </div>
                          </Link>{" "}
                        </li>

                        <li>
                          <Link to="/profile" className="dropdown-item">
                            <div className="dropdown-body-item pt-3 border-top">
                              Trading Fees
                            </div>
                          </Link>
                        </li>

                        <li>
                          <Link to="/AccountSecurity" className="dropdown-item">
                            <div className="dropdown-body-item">
                              Account Security
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/AccountVerification"
                          >
                            <div className="dropdown-body-item">
                              {" "}
                              Identity Verification{" "}
                              <span className="custom_badge bg-soft-pink">
                                LV3
                              </span>
                            </div>
                          </Link>
                        </li>

                        <li>
                          <Link className="dropdown-item" to="/ApiManagement">
                            <div className="dropdown-body-item">
                              API Management
                            </div>
                          </Link>
                        </li>

                        <li>
                          <Link className="dropdown-item" to="/subAccount">
                            <div className="dropdown-body-item">
                              {" "}
                              Sub Account{" "}
                            </div>
                          </Link>
                        </li>

                        <li>
                          <a
                            className="dropdown-item cursor_pointer border-top"
                            onClick={() => {
                              backRouter();
                            }}
                          >
                            <div className="dropdown-body-item text-center text-pink">
                              Log Out
                            </div>
                          </a>
                        </li>
                      </ul> */}
                    </li>

                    <li className="nav-item header-nav-item ">
                      <a
                        data-bs-toggle="offcanvas"
                        href="#notification_toggle"
                        role="button"
                        aria-controls="notification_toggle"
                        className="nav-link p-0"
                      >
                        <div className="circle_backdrop">
                          <i className="fa fa-bell-o fs-6"></i>
                        </div>
                      </a>
                    </li>
                  </>
                ) : null}

                {/* <li className="nav-item header-nav-item  header-nav-item ">
                    <a
                      onClick={() => {
                        swtchTheme();
                      }}
                      className="nav-link p-0"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="circle_backdrop">
                        <i class="fa fa-sun fs-5" id="theme_icon"></i>
                      </div>
                    </a>
                  </li> */}

                <span className="brand-title">
                  {" "}
                  {webData.logo_with_title === 1
                    ? webData.website_short_name
                    : ""}
                </span>
                <button
                  class="navbar-toggler circle_backdrop"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#ca-navbar"
                  aria-controls="ca-navbar "
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon">
                    <HiBars3CenterLeft className="fs-3" />
                  </span>
                </button>
              </div>
            ) : null}
          </div>
          {/*============= mobile Menu (Account | Notification| Toggle bar) End ============= */}
          {props?.location?.pathname != "/login" &&
          props?.location?.pathname != "/create" ? (
            <div
              id="ca-navbar"
              className="offcanvas offcanvas-end navbar-collapse-- manu_header"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="ca-navbar">
                  <Link className="navbar-brand" to="/">
                    <img
                      className="ms-2"
                      src={`${adminurl}${webData?.logo_img_name}`}
                      alt="logo"
                      width="100"
                    />
                  </Link>
                </h5>
                <button
                  type="button"
                  class="circle_backdrop border-0"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <RxCross1 />
                </button>
              </div>
              <div className="offcanvas-body">
                <ul id="nav--" className="navbar-nav me-auto">
                  {/* <li class="nav-item header-nav-item  dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Buy Crypto
                    </a>
                    <ul
                      class="dropdown-menu dropdown_body_custom"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <a class="dropdown-item" href="/FastTrade">
                          <div class="dropdown-body-item d-flex">
                            <div class="dropdown-body-item-icon">
                              <img
                                src="https://download.hbfile.net/hbg/img/202211221432/58af44b1580f490bab830b60db1228c8/967d863b122213531718da493d9b8c69.png"
                                alt=""
                                class="dropdown-body-item-icon-img"
                              />
                            </div>
                            <div class="dropdown-body-item-content">
                              <div class="dropdown-body-item-title">
                                Fast Trade
                              </div>
                              <div class="dropdown-body-item-subtitle">
                                Buy cryptos with Visa, Mastercard, and more
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          <div class="dropdown-body-item d-flex">
                            <div class="dropdown-body-item-icon">
                              <img
                                src="https://download.hbfile.net/hbg/img/202211221432/58af44b1580f490bab830b60db1228c8/967d863b122213531718da493d9b8c69.png"
                                alt=""
                                class="dropdown-body-item-icon-img"
                              />
                            </div>
                            <div class="dropdown-body-item-content">
                              <div class="dropdown-body-item-title">P2P</div>
                              <div class="dropdown-body-item-subtitle">
                                Bank Transfer and 20+ options
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>

                      <li>
                        <a class="dropdown-item" href="#">
                          <div class="dropdown-body-item d-flex">
                            <div class="dropdown-body-item-icon">
                              <img
                                src="https://download.hbfile.net/hbg/img/202211221432/58af44b1580f490bab830b60db1228c8/967d863b122213531718da493d9b8c69.png"
                                alt=""
                                class="dropdown-body-item-icon-img"
                              />
                            </div>
                            <div class="dropdown-body-item-content">
                              <div class="dropdown-body-item-title">
                                Third Party
                              </div>
                              <div class="dropdown-body-item-subtitle">
                                Banxa, Simplex. BTC , Direct
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          <div class="dropdown-body-item d-flex">
                            <div class="dropdown-body-item-icon">
                              <img
                                src="https://download.hbfile.net/hbg/img/202211221432/58af44b1580f490bab830b60db1228c8/967d863b122213531718da493d9b8c69.png"
                                alt=""
                                class="dropdown-body-item-icon-img"
                              />
                            </div>
                            <div class="dropdown-body-item-content">
                              <div class="dropdown-body-item-title">
                                Fiat Deposit
                              </div>
                              <div class="dropdown-body-item-subtitle">
                                Topup Fiat Balance via Bank Transfer
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </li> */}
                  <li className="nav-item header-nav-item ">
                    <Link className=" nav-link" to="/Markets">
                      Market
                    </Link>
                  </li>

                  {/* <li className="nav-item header-nav-item ">
                    <Link className="nav-link" to="/">
                      Web3
                    </Link>
                  </li> */}

                  <li class="nav-item header-nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More
                    </a>
                    <ul
                      class="dropdown-menu dropdown_body_custom mega-dropdown-menu-more"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <div className="row pt-4">
                          <div className="col-lg-6 mb-4">
                            <h4 className="h6 text-lgray px-5"> Promotions</h4>
                            <ul>
                              <li>
                                <a class="dropdown-item" href="/AffiliateHome">
                                  <div class="dropdown-body-item d-flex">
                                    <div class="dropdown-body-item-icon">
                                      <img
                                        src="https://download.hbfile.net/hbg/img/202211221432/58af44b1580f490bab830b60db1228c8/967d863b122213531718da493d9b8c69.png"
                                        alt=""
                                        class="dropdown-body-item-icon-img"
                                      />
                                    </div>
                                    <div class="dropdown-body-item-content">
                                      <div class="dropdown-body-item-title">
                                        Referral Program
                                      </div>
                                      <div class="dropdown-body-item-subtitle">
                                        Reder friends to earn a 20% commisions
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>

                              <li>
                                <a class="dropdown-item" href="/AffiliateHome">
                                  <div class="dropdown-body-item d-flex">
                                    <div class="dropdown-body-item-icon">
                                      <img
                                        src="https://download.hbfile.net/hbg/img/202211221432/58af44b1580f490bab830b60db1228c8/967d863b122213531718da493d9b8c69.png"
                                        alt=""
                                        class="dropdown-body-item-icon-img"
                                      />
                                    </div>
                                    <div class="dropdown-body-item-content">
                                      <div class="dropdown-body-item-title">
                                        Affiliate
                                      </div>
                                      <div class="dropdown-body-item-subtitle">
                                        Join BitPlug Program and earn commisions
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>

                              <li>
                                <a class="dropdown-item" href="/RewardHub ">
                                  <div class="dropdown-body-item d-flex">
                                    <div class="dropdown-body-item-icon">
                                      <img
                                        src="https://download.hbfile.net/hbg/img/202211221432/58af44b1580f490bab830b60db1228c8/967d863b122213531718da493d9b8c69.png"
                                        alt=""
                                        class="dropdown-body-item-icon-img"
                                      />
                                    </div>
                                    <div class="dropdown-body-item-content">
                                      <div class="dropdown-body-item-title">
                                        Reward Hub
                                      </div>
                                      <div class="dropdown-body-item-subtitle">
                                        Join BitPlug Program and earn commisions
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                          {/* <div className="col-lg-6 mb-4">
                            <h4 className="h6 text-lgray px-5"> Application</h4>
                            <ul>
                              <li>
                                <a class="dropdown-item" href="/RewardHub">
                                  <div class="dropdown-body-item d-flex">
                                    <div class="dropdown-body-item-icon">
                                      <img
                                        src="https://download.hbfile.net/hbg/img/202211221432/58af44b1580f490bab830b60db1228c8/967d863b122213531718da493d9b8c69.png"
                                        alt=""
                                        class="dropdown-body-item-icon-img"
                                      />
                                    </div>
                                    <div class="dropdown-body-item-content">
                                      <div class="dropdown-body-item-title">
                                        API Services
                                      </div>
                                      <div class="dropdown-body-item-subtitle">
                                        Exclusive perks for API Users
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>

                              <li>
                                <a class="dropdown-item" href="/AffiliateHome">
                                  <div class="dropdown-body-item d-flex">
                                    <div class="dropdown-body-item-icon">
                                      <img
                                        src="https://download.hbfile.net/hbg/img/202211221432/58af44b1580f490bab830b60db1228c8/967d863b122213531718da493d9b8c69.png"
                                        alt=""
                                        class="dropdown-body-item-icon-img"
                                      />
                                    </div>
                                    <div class="dropdown-body-item-content">
                                      <div class="dropdown-body-item-title">
                                        KCC
                                      </div>
                                      <div class="dropdown-body-item-subtitle">
                                        DeFi, NFT, and Web 3.0
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>

                              <li>
                                <a class="dropdown-item" href="/AffiliateHome">
                                  <div class="dropdown-body-item d-flex">
                                    <div class="dropdown-body-item-icon">
                                      <img
                                        src="https://download.hbfile.net/hbg/img/202211221432/58af44b1580f490bab830b60db1228c8/967d863b122213531718da493d9b8c69.png"
                                        alt=""
                                        class="dropdown-body-item-icon-img"
                                      />
                                    </div>
                                    <div class="dropdown-body-item-content">
                                      <div class="dropdown-body-item-title">
                                        KuCard
                                      </div>
                                      <div class="dropdown-body-item-subtitle">
                                        Spend crypto and earn cashback
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                        {/* <div className="row">
                          <div className="col-lg-6">
                            <h4 className="h6 text-lgray px-5"> Information</h4>
                            <ul>
                              <li>
                                <a class="dropdown-item" href="/RewardHub">
                                  <div class="dropdown-body-item d-flex">
                                    <div class="dropdown-body-item-icon">
                                      <img
                                        src="https://download.hbfile.net/hbg/img/202211221432/58af44b1580f490bab830b60db1228c8/967d863b122213531718da493d9b8c69.png"
                                        alt=""
                                        class="dropdown-body-item-icon-img"
                                      />
                                    </div>
                                    <div class="dropdown-body-item-content">
                                      <div class="dropdown-body-item-title">
                                        Announcement
                                      </div>
                                      <div class="dropdown-body-item-subtitle">
                                        Latest activities and information
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>

                              <li>
                                <a class="dropdown-item" href="/AffiliateHome">
                                  <div class="dropdown-body-item d-flex">
                                    <div class="dropdown-body-item-icon">
                                      <img
                                        src="https://download.hbfile.net/hbg/img/202211221432/58af44b1580f490bab830b60db1228c8/967d863b122213531718da493d9b8c69.png"
                                        alt=""
                                        class="dropdown-body-item-icon-img"
                                      />
                                    </div>
                                    <div class="dropdown-body-item-content">
                                      <div class="dropdown-body-item-title">
                                        Blog
                                      </div>
                                      <div class="dropdown-body-item-subtitle">
                                        Blockchain and crypto information
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="/AffiliateHome">
                                  <div class="dropdown-body-item d-flex">
                                    <div class="dropdown-body-item-icon">
                                      <img
                                        src="https://download.hbfile.net/hbg/img/202211221432/58af44b1580f490bab830b60db1228c8/967d863b122213531718da493d9b8c69.png"
                                        alt=""
                                        class="dropdown-body-item-icon-img"
                                      />
                                    </div>
                                    <div class="dropdown-body-item-content">
                                      <div class="dropdown-body-item-title">
                                        Security
                                      </div>
                                      <div class="dropdown-body-item-subtitle">
                                        Security measures for protecting user
                                        assets
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item header-nav-item d-lg-block d-none">
                    <Link className="nav-link" to="/overview">
                      <span className="custom_badge bg-soft-pink">
                        Assets Overview
                      </span>
                    </Link>
                  </li>

                  {/* {isLoggedIn ? (
                  <li className="nav-item header-nav-item ">
                    <Link className="nav-link" to="/wallet">
                      Wallets
                    </Link>
                  </li>
                  ) : null} */}
                </ul>

                {/*============= Right Side header Manu============= */}
                <div className="">
                  <ul className="navbar-nav custom_dropdown_menu">
                    {!isLoggedIn ? (
                      <li class="nav-item header-nav-item ">
                        <Link className="nav-link " to="/login">
                          Log In
                        </Link>
                      </li>
                    ) : null}
                    {!isLoggedIn ? (
                      <li class="nav-item header-nav-item ">
                        <Link
                          className="nav-link signup-btn rounded-pill"
                          to="/create"
                        >
                          Sign Up
                        </Link>
                      </li>
                    ) : null}
                    {isLoggedIn ? (
                      <>
                        <li class="nav-item header-nav-item dropdown">
                          <a
                            class="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Assets
                          </a>
                          <ul
                            class="dropdown-menu dropdown-menu-end- dropdown_body_custom w320px"
                            aria-labelledby="navbarDropdown"
                          >
                            <li>
                              <a class="dropdown-item pb-0" href="/overview">
                                <div className="dropdown-body-item pb-0 text-lgray">
                                  <div className="fs-14">
                                    {" "}
                                    Overview <FaRegEye />
                                  </div>
                                  <div>
                                    <span className="text-dark">
                                      {total_usdt}
                                    </span>{" "}
                                    <span> USDT</span>
                                  </div>

                                  <div>
                                    {" "}
                                    <span> {total_inr} INR</span>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/fundingMain">
                                <div className="dropdown-body-item">
                                  <GoGraph className="fs-4 me-2" /> Funding
                                  Account
                                </div>
                              </a>
                            </li>

                            {/* <li>
                              <a
                                class="dropdown-item"
                                href="/AssetsTradingAccount"
                              >
                                <div className="dropdown-body-item">
                                  {" "}
                                  <RiExchangeFundsFill className="fs-4 me-2" />{" "}
                                  Trading Account
                                </div>
                              </a>
                            </li> */}

                            {/* <li>
                              <a class="dropdown-item" href="/AssetsMargin">
                                <div className="dropdown-body-item">
                                  {" "}
                                  <FaBalanceScaleLeft className="fs-4 me-2" />{" "}
                                  Margin Account
                                </div>
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/AssetsFuture">
                                <div className="dropdown-body-item">
                                  <MdOutlineAutoGraph className="fs-4 me-2" />
                                  Futures Account
                                </div>
                              </a>
                            </li> */}

                            {/* <li>
                              <a class="dropdown-item" href="/TradingBot">
                                <div className="dropdown-body-item">
                                  <BiBot className="fs-4 me-2" />
                                  Trading Bot Account
                                </div>
                              </a>
                            </li>

                            <li>
                              <a class="dropdown-item" href="/AssetsFinance">
                                <div className="dropdown-body-item">
                                  {" "}
                                  <FaHandHoldingUsd className="fs-4 me-2" />{" "}
                                  Financial Account{" "}
                                </div>
                              </a>
                            </li> */}

                            <div className="mx-2">
                              <div className="dropdown-body-item pb-0 mb-0">
                                <div className="menu_jambotron">
                                  <Link class="" to="/exchange/trx-inr">
                                    <div className="d-flex justify-content-between">
                                      Buy Crypto
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>

                            <div className="mx-2">
                              <div className="dropdown-body-item ">
                                <div className="menu_jambotron">
                                  <Link class="" to="/AssetsDeposit">
                                    <div className="d-flex justify-content-between">
                                      Assets Deposit
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>

                            <div className="mx-2">
                              <div className="dropdown-body-item ">
                                <div className="menu_jambotron">
                                  <Link class="" to="/AssetsWithdraw">
                                    <div className="d-flex justify-content-between">
                                      Assests Withdraw
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </ul>
                        </li>

                        <li class="nav-item header-nav-item dropdown">
                          <a
                            class="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Orders
                          </a>
                          <ul
                            class="dropdown-menu dropdown-menu-end dropdown_body_custom w320px"
                            aria-labelledby="navbarDropdown"
                          >
                            <li>
                              <a class="dropdown-item" href="/SpotOrders">
                                <div className="dropdown-body-item">
                                  <FaRegDotCircle className="fs-4 me-2" /> Spot
                                  Orders
                                </div>
                              </a>
                            </li>
                            {/* 
                            <li>
                              <a class="dropdown-item" href="/MarginOrder">
                                <div className="dropdown-body-item">
                                  {" "}
                                  <FaBalanceScaleLeft className="fs-4 me-2" />{" "}
                                  Margin Orders
                                </div>
                              </a>
                            </li> */}

                            {/* <li>
                              <a class="dropdown-item" href="/AssetsFuture">
                                <div className="dropdown-body-item">
                                  {" "}
                                  <RiExchangeFundsFill className="fs-4 me-2" />{" "}
                                  Futures Orders
                                </div>
                              </a>
                            </li> */}
                            {/* <li>
                              <a class="dropdown-item" href="/Earn">
                                <div className="dropdown-body-item">
                                  <RiExchangeDollarLine className="fs-5 me-2" />
                                  Earn Orders
                                </div>
                              </a>
                            </li> */}

                            <li>
                              <a class="dropdown-item" href="/CryptoOrders">
                                <div className="dropdown-body-item">
                                  <CiMoneyBill className="fs-4 me-2" />
                                  Buy Crypto Orders
                                </div>
                              </a>
                            </li>
                            <li>
                              <hr class="dropdown-divider" />
                            </li>

                            <li>
                              <a class="dropdown-item" href="/SpotOrders">
                                <div className="dropdown-body-item">
                                  {" "}
                                  <TbHistoryToggle className="fs-4 me-2" /> Spot
                                  Trade History{" "}
                                </div>
                              </a>
                            </li>

                            {/* <li>
                              <a class="dropdown-item" href="/MarginOrder">
                                <div className="dropdown-body-item">
                                  {" "}
                                  <TbHistoryToggle className="fs-4 me-2" />{" "}
                                  Margin Trade History{" "}
                                </div>
                              </a>
                            </li> */}

                            {/* <li>
                              <a class="dropdown-item" href="/AssetsFuture">
                                <div className="dropdown-body-item">
                                  {" "}
                                  <TbHistoryToggle className="fs-4 me-2" />{" "}
                                  Futures Trade History{" "}
                                </div>
                              </a>
                            </li> */}
                          </ul>
                        </li>

                        <li
                          class="nav-item header-nav-item dropdown d-none d-lg-block"
                          id="user_link"
                        >
                          <a
                            class="nav-link py-0 dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            <div class="circle_backdrop_outline gray_border">
                              {profile?.name
                                ? profile?.name
                                    ?.split("")
                                    .slice(0, 2)
                                    .join("")
                                    .toUpperCase()
                                : profile?.email
                                    ?.split("")
                                    .slice(0, 2)
                                    .join("")
                                    .toUpperCase()}
                            </div>
                          </a>

                          <ul class="dropdown-menu dropdown_body_custom dropdown-menu-end w320px pb-0">
                            <li>
                              <Link to="/profile" className="dropdown-item">
                                <div className="dropdown-body-item border-bottom">
                                  <h2 className="big_heading mb-2 text-truncate">
                                    {profile?.name
                                      ? profile.name
                                      : profile?.email}
                                  </h2>
                                  {/* <span className="custom_badge gray_badge text-lgray rounded-pill">
                                    {" "}
                                    UID :{" "}
                                    {user?.params
                                      ? user.params.user_id
                                      : user.user_id}{" "}
                                    <i className="fa fa-copy ms-1"></i>
                                  </span> */}
                                </div>
                              </Link>
                            </li>

                            {/* <li>
                              {" "}
                              <Link
                                to={`/exchange/${currency_type}-${campare_currency}`}
                                className="dropdown-item"
                              >
                                <div className="dropdown-body-item">
                                  <div className="d-flex justify-content-between fs-12 text-lgray">
                                    <span>Spot</span>
                                    <span>
                                      Maker/Taker:{" "}
                                      <span className="text-dark">
                                        0.08% / 0.08%
                                      </span>
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between fs-12 text-lgray">
                                    <span>Futures</span>
                                    <span>
                                      Maker/Taker:{" "}
                                      <span className="text-dark">
                                        0.02% / 0.06%
                                      </span>
                                    </span>
                                  </div>

                                  <div className="fs-12 mt-3 pt-2">
                                    Pay Fee with KCS (20% off):{" "}
                                    <span className="text-green">Enabled</span>
                                  </div>
                                </div>
                              </Link>{" "}
                            </li> */}

                            {/* <li>
                              <Link to="/profile" className="dropdown-item">
                                <div className="dropdown-body-item pt-3 border-top">
                                  Trading Fees
                                </div>
                              </Link>
                            </li> */}

                            <li>
                              <Link
                                to="/AccountSecurity"
                                className="dropdown-item"
                              >
                                <div className="dropdown-body-item">
                                  Account Security
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/AccountVerification"
                              >
                                <div className="dropdown-body-item">
                                  {" "}
                                  Identity Verification{" "}
                                  <span className="custom_badge bg-soft-pink">
                                    LV1
                                  </span>
                                </div>
                              </Link>
                            </li>

                            {/* <li>
                              <Link
                                className="dropdown-item"
                                to="/ApiManagement"
                              >
                                <div className="dropdown-body-item">
                                  API Management
                                </div>
                              </Link>
                            </li>

                            <li>
                              <Link className="dropdown-item" to="/subAccount">
                                <div className="dropdown-body-item">
                                  {" "}
                                  Sub Account{" "}
                                </div>
                              </Link>
                            </li> */}

                            <li>
                              <a
                                className="dropdown-item cursor_pointer border-top"
                                onClick={() => {
                                  backRouter();
                                }}
                              >
                                <div className="dropdown-body-item text-center text-pink">
                                  Log Out
                                </div>
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className="nav-item header-nav-item d-none d-lg-block">
                          <a
                            data-bs-toggle="offcanvas"
                            href="#notification_toggle"
                            role="button"
                            aria-controls="notification_toggle"
                            className="nav-link p-0"
                          >
                            <div className="circle_backdrop">
                              <i className="fa fa-bell-o fs-6"></i>
                            </div>
                          </a>
                        </li>
                      </>
                    ) : null}

                    {/* <li className="nav-item header-nav-item header-nav-item">
                      <a
                        onClick={() => {
                          swtchTheme();
                        }}
                        className="nav-link p-0"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="circle_backdrop">
                          <i class="fa fa-sun fs-5" id="theme_icon"></i>
                        </div>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
        </nav>
      </div>

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="notification_toggle"
        aria-labelledby="notification_toggleLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="notification_toggleLabel">
            Notifications
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div>news1</div>
        </div>
      </div>
    </>
  );
}
