import React from "react";
import { useSelector } from "react-redux";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FaCoins } from "react-icons/fa";
import { BiGitCompare } from "react-icons/bi";
import { HiCubeTransparent } from "react-icons/hi";
import Card from "./Card";
import AOS from "aos";
import "aos/dist/aos.css";
import { getRound } from "../redux/helpers/helper_functions";

export default function MarketTrend() {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  AOS.init();
  const { coins, campare_currency } = useSelector((state) => state.coinDBReducer);
  let coins1 = Object.values(coins);
  // console.log(coins1)
  const market_child =
    coins1 &&
    coins1.map((item, index) => {
      let usdtprize = coins1 && coins1.find((item) => item.symbol == "USDT");
      return (
        <>
          {item.symbol == "BTC" ||
          item.symbol == "BNB" ||
          item.symbol == "ETH" ||
          item.symbol == "TRX" ||
          item.symbol == "BitPlug" ? (
            <tr>
              <td>
                <img
                  src={item.icon}
                  alt="coin symbole"
                  className="market_coin_img"
                  //style={{ width: "50px" }}
                />
                {item.symbol}
                <span className="name_coin ms-2">{item.name}</span>
              </td>
              <td>
                $
                {getRound(
                  item.current_price / usdtprize.current_price
                )}
              </td>
              <td>
                <span
                  className={
                    item.direction_inr === "up" ? "text-green " : "text-red"
                  }
                >
                  {getRound(item.price_change_percentage_1h)}%{" "}
                  {item.direction === "up" ? (
                    <BsArrowUpShort />
                  ) : (
                    <BsArrowDownShort />
                  )}
                </span>
              </td>
              <td>{getRound(item.market_cap)}</td>
              <td>
                <a
                  href={`/exchange/${item.symbol.toLowerCase()}-${campare_currency}`}
                >
                  Trade
                </a>
              </td>
            </tr>
          ) : (
            ""
          )}
        </>
      );
    });
  return (
    <>
      <div className="spacer">
        <div className="container">
          <div className="para_heading">Market Trend</div>
          <div className="market_trend_section">
            <div className="overflow-auto">
              <div className="table_scroll_div">
                <table className="table home_table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Last Price</th>
                      <th>24h Change</th>
                      <th>Market Cap</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{market_child}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="spacer features_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="para_heading">New Users Only</h2>
              <h5 className="text-dgray mb-5">
                Start trading and Join over 300,000 global clients†​
              </h5>

              <div className="timeline_box">
                <div className="">
                  <div className="timeline_no active"> 1</div>
                  <div className="timeline_line"></div>
                </div>
                <div>
                  {" "}
                  <h3 class="">
                    <a href="#" class="text-dark">
                      Apply
                    </a>
                  </h3>
                  <p>Complete our simple online application form.</p>
                </div>
              </div>
              <div className="timeline_box">
                <div className="">
                  <div className="timeline_no"> 2</div>
                  <div className="timeline_line"></div>
                </div>
                <div>
                  {" "}
                  <h3 class="">
                    <a href="#" class="text-dark">
                      Set Up
                    </a>
                  </h3>
                  <p>
                    Log in to the Aconiomic admin portal to set up your account.
                  </p>
                </div>
              </div>
              <div className="timeline_box">
                <div className="">
                  <div className="timeline_no"> 3</div>
                  <div className="timeline_line"></div>
                </div>
                <div>
                  {" "}
                  <h3 class="">
                    <a href="#" class="text-dark">
                      Fund
                    </a>
                  </h3>
                  <p>
                    Deposit funds from a debit card or bank transfer. No minimum
                    deposit required.
                  </p>
                </div>
              </div>

              <div className="timeline_box">
                <div className="">
                  <div className="timeline_no"> 4</div>
                  {/* <div className="timeline_line"></div> */}
                </div>
                <div>
                  {" "}
                  <h3 class="">
                    <a href="#" class="text-dark">
                      Trade
                    </a>
                  </h3>
                  <p>
                    Log in to the BitPlug platform and connect to the CMC
                    server to start trading.
                  </p>
                </div>
              </div>

              <div className="mt-4">
                {" "}
                {!isLoggedIn ? (
                  <>
                    <a className="btn login_btn px-4 rounded-pill" to="/create">
                      Create Account
                    </a>
                  </>
                ) : (

                 <>
                  {/* <div className="text-green mb-2"> Congratulations ! You have already created account with us.  </div>
                  <a
                    className="btn btn-pink btn-lg px-5"
                    href="/exchange/rbc-inr"
                  >
                    Enjoy Trading
                  </a> */}
                  
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6 text-center">
              {" "}
              <img src="/img/new-user.png" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div className="spacer mt-4 app_section">
        <div className="container">
          <div className="row d-flex align-center">
            <div className="col-md-6 mb-5">
              <h2 className="para_heading">Trade anytime, anywhere </h2>
              <h5>
                Download our newly launched app available on Android and Apple
                Devices
              </h5>

              <div className="d-flex align-center justify-content-start my-5 gap-3">
                {" "}
                <div>
                  <img src="/img/apple-app.svg" className="img-fluid" />{" "}
                </div>
                <div>
                  <img
                    src="/img/get-it-on-google-play.png"
                    style={{ height: "40px" }}
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 col-12 d-flex justify-content-center"
              data-aos="fade-up"
            >
              <img src="/img/trade-anywhere.png" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="spacer key_features">
        <div className="container">
          <div className="para_heading text-center">BitPlug by Your Side</div>
          <div className="row d-flex align-center justify-content-center mt-5">
            <div className="col-lg-4 col-md-6 col-12 d-flex justify-content-center">
              <div className="card bg-transparent border-0">
                <div className="card-body padding_5">
                  <FaCoins className="icons" />
                  <h4 className="">Real-Time Price</h4>
                  <p>
                    Get latest crypto prices, interactive charts, and technical
                    analysis for digital currencies.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 d-flex justify-content-center">
              <div className="card bg-transparent border-0">
                <div className="card-body padding_5">
                  <BiGitCompare className="icons" />
                  <h4>Comparisons</h4>
                  <p>
                    Compare performance of your cryptocurrencies with Coinread.
                    Graphs, market cap, price, circulating supply, max supply
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 d-flex justify-content-center">
              <div className="card bg-transparent border-0">
                <div className="card-body padding_5">
                  <HiCubeTransparent className="icons" />
                  <h4>Transparent</h4>
                  <p>
                    Find the crypto data you need whether you’re looking to
                    trade on a new to the exchange, invest in a fresh currency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
