import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function Privacy(props) {
  useEffect(() => {
    document.title = "BitPlug Privacy policy";
  }, []);

  return (
    <>
      <Header {...props} />
      <div className="wrap-privacy d-flex align-items-center ">
        <div className="container py-5">
          <div className="d-block mx-auto">
            <h1 className="text-left font-size-40">
              <span style={{ color: "#04DA9A" }}>Privacy Policies</span>
            </h1>
          </div>
          <p className="para">
            The website and mobile app operated under the brand name –{" "}
            <span style={{ color: "#04DA9A" }}>“BitPlug</span> is owned by
            Bitcipher Labs LLP (“We”, “Us”, “LLP” or “Our”, which expression
            shall mean and include its affipates, successors and assigns).
          </p>
          <p className="para">
            THIS POpCY DESCRIBES OUR POpCIES AND PROCEDURES FOR COLLECTION, USE,
            STORAGE, DISCLOSURE AND PROTECTION OF ANY PERSONAL INFORMATION,
            INCLUDING, BUT NOT pMITED TO, BUSINESS OR PERSONAL INFORMATION
            PROVIDED BY YOU (“YOU/R”) WHILE USING THE PLATFORM.
          </p>
          <p className="para">
            THIS POpCY CONSTITUTES A LEGAL AGREEMENT BETWEEN YOU, AS THE USER OF
            THE PLATFORM, AND US, AS THE OWNER OF THE PLATFORM. BY VOLUNTARILY
            PROVIDING US OR ALLOWING US TO ACCESS THE PERSONAL INFORMATION, YOU
            ARE CONSENTING TO OUR USE OF IT IN ACCORDANCE WITH THIS POpCY. BY
            MERE VISITING/ ACCESSING THE PLATFORM, YOU EXPRESSLY CONSENT TO
            LLP’S USE AND DISCLOSURE OF YOUR PERSONAL INFORMATION IN ACCORDANCE
            WITH THIS PRIVACY POpCY. THIS POpCY DOES NOT APPLY TO THIRD-PARTY
            PLATFORMS THAT ARE CONNECTED VIA pNKS TO THE PLATFORM. IF YOU DO NOT
            AGREE WITH THE TERMS AND CONDITIONS OF THIS POpCY, PLEASE DO NOT
            PROCEED FURTHER TO USE/ ACCESS THIS PLATFORM. ALSO, IN THE ABSENCE
            OF AN ACCEPTANCE OF THE POpCY BY YOU, WE WILL NOT BE ABLE TO PROVIDE
            THE SERVICES TO YOU.
          </p>
          <p className="para">
            YOUR USE OF THE PLATFORM WILL BE GOVERNED BY THIS POpCY IN ADDITION
            TO THE TERMS OF USE AS APPpCABLE TO YOU.
          </p>
          <p className="para">
            THIS POpCY HAS BEEN DRAFTED IN ACCORDANCE WITH THE INFORMATION
            TECHNOLOGY ACT, 2000 AND THE DATA PROTECTION RULES AND REGULATIONS
            OF INDIA.
          </p>

          <h2 style={{ color: "#04DA9A" }}>
            {" "}
            <span>1.</span> Information Collected
          </h2>
          <p>
            We will use the information provided by You only in accordance with
            the purposes described in the Popcy and the AML Popcy.
          </p>
          <p>
            We collect Your Personal Information or Non-Personal Information (as
            hereinafter defined) when use the Platform and at the time of Your
            registration with the Platform, i.e. create an account on the
            Platform to avail our services. Further, We collect information from
            You during the performance of Services for You. We collect
            information with respect to Your name, email, address, IP Address
            and zip code, Your location, device ID, Device location. We collect
            all of the foregoing information in order to provide You the
            services.
          </p>
          <p>
            Except as provided herein or as clearly disclosed to You at the time
            of collection, We do not sopcit any Sensitive Information about You.
            However, if You share such information with Us voluntarily, We will
            not be pable for any actions, claims, costs, expenses or other
            pabipties that may arise as a consequence of any unauthorized use or
            misuse of such information.
          </p>
          <p>
            During Your use of the Platform, We may collect and process such
            information from You, including but not pmited to the below
            mentioned:
          </p>

          <h4 style={{ color: "#04DA9A" }}>Personal Information:</h4>
          <p>
            <b>“Personal Information”</b> means and includes:
          </p>

          <p>
            <span style={{ color: "#04DA9A" }}>i.</span> Information that You
            provide to Us by filpng in forms on the Platform. This may include
            contact information such as name, email address, maipng address,
            phone number;
          </p>
          <p>
            <span style={{ color: "#04DA9A" }}>ii.</span> Information that You
            voluntarily provide when You write directly to Us (including by
            e-mail and surveys);
          </p>
          <p>
            <span style={{ color: "#04DA9A" }}>iii.</span> Know Your Customer
            (“KYC”) Documents and data that You provide to Us.
          </p>
          <p>
            <span style={{ color: "#04DA9A" }}>iv.</span> Information that You
            specifically provide access to while accepting the Terms and
            Conditions of use of the Platform.
          </p>

          <h4 style={{ color: "#04DA9A" }}>Non-Personal Information</h4>
          <p>
            {" "}
            <b>“Non-Personal Information”</b> means and includes any information
            that does not reveal Your specific identity, such as, browser
            information, information collected through Cookies (as defined
            below), pixel tags and other technologies, demographic information
            etc. Our Platform gathers some information automatically when You
            visit the Platform and stores it in log files. We may collect
            certain information about Your computer or other electronic device
            through which You have accessed the Platform to facilitate, evaluate
            and verify Your use of the Platform.
          </p>

          <p>
            {" "}
            <b>“Cookies”</b> are small files that reside on Your computer's hard
            drive and generally contain an anonymous unique identifier and are
            accessible only by the Platform that placed them there and not any
            other sites. You may refuse to accept Cookies by activating the
            setting on Your browser which allows You to refuse the setting of
            Cookies. However, if You select this setting You may be unable to
            access certain parts of the Platform. Unless You have adjusted Your
            browser setting so that it will refuse Cookies, Our system may issue
            Cookies when You log on to the Platform. The use of Cookies by Our
            partners, affiliates, advertisers or service providers is not
            covered by the Policy.
          </p>

          <h2 style={{ color: "#04DA9A" }}>
            <span>2.</span> Use of the Information
          </h2>
          <p>
            We may use and share the information provided by or collected from
            You in the following ways, viz:
          </p>

          <ol type="a" className="p-4">
            <li style={{ color: "#04DA9A" }}>
              <span>
                provide and improve the services offered to You through the
                Platform;
              </span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>undertake KYC checks and verifications;</span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>establish and verify Your identity;</span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>
                conduct research and analysis to detect, prevent, mitigate and
                investigate fraudulent or illegal activities
              </span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>
                analyse how the Platform is used, diagnose service or technical
                problems, maintain security;
              </span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>help You efficiently use and access the Platform;</span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>send You notifications, updated and newsletters;</span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>
                monitor aggregate metrics such as total number of viewers,
                visitors, traffic, and demographic patterns;
              </span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>
                to enable Us to comply with any legal and regulatory obligations
                or requisition requests;
              </span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>to enforce our legal rights against You;</span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>
                complete know-Your customer requirements and on-boarding.
              </span>
            </li>
          </ol>
          <p>
            We will use and retain Your information for such periods as
            necessary to comply with any applicable laws, Our legal obligations,
            resolve disputes, record keeping and enforce this agreement.
          </p>

          <h2 style={{ color: "#04DA9A" }}>
            3. Sharing and Disclosure of Information
          </h2>
          <p>
            We may disclose Your information to third parties for the purposes
            mentioned under Section 2 above, i.e., in order to perform KYC
            checks, provide You with customer support facilities, to comply with
            Our legal obligations including prevention of money laundering, to
            enforce Our Terms of Use, to facilitate Our marketing and
            advertising activities, to conduct analysis, to provide You
            services, to facilitate services for You, to send notifications,
            reminders or to prevent, detect, mitigate, and investigate
            fraudulent or illegal activities related to the Platform and to
            avail services from third parties for data analysis and market
            research.
          </p>

          <p>
            In the event the LLP is merged with or acquired by another LLP or in
            case of re-organization or re-structuring of business, We and Our
            affiliates will share Your Personal Information, wholly or in part,
            with another business entity.
          </p>
          <p>
            We will share Your information with third parties only in such
            manner as described below:
          </p>
          <ol type="a" className="p-4">
            <li style={{ color: "#04DA9A" }}>
              <span>
                {" "}
                We may share Your information with third parties including third
                party service providers/agents/partners, etc. and in such an
                event, the third parties’ use of Your information will be bound,
                among other things, with obligation to protect the
                confidentiality of Your information. We may store information in
                locations outside the direct control of the LLP (for instance,
                on servers or databases co-located with hosting providers) and
                Your use of the Platform shall constitute consent for the same
              </span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>
                {" "}
                We may disclose Your information, to any member of Our related
                or group companies including but not limited to, our
                subsidiaries, Our ultimate holding LLP and its subsidiaries, as
                the case may be, and Your use of the Platform shall constitute
                consent for the same.
              </span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>
                {" "}
                We may disclose Your information if We are under a duty to do so
                in order to comply with any legal obligation or an order from
                the government and/or a statutory authority, or in order to
                enforce or apply Our Terms of Use or assign such information in
                the course of corporate divestitures, mergers, or to protect the
                rights, property, or safety of Us, Our users, or others. This
                includes exchanging information with other companies and
                organizations for the purposes of fraud protection and credit
                risk reduction.
              </span>
            </li>
          </ol>
          <h2 style={{ color: "#04DA9A" }}>
            <span>4.</span> Storage, Security and Disclosure of Information
          </h2>
          <p>
            We use commercially reasonable safeguards to preserve the integrity
            and security of Your information and data against loss, theft,
            unauthorised access, disclosure, reproduction, use or amendment. All
            information You provide to Us is stored on Our secure servers within
            India. We may also store/move Your data on Our Servers outside
            India, if required in Our discretion. You hereby consent to Your
            data being stored outside India as well.
          </p>

          <p>
            By submitting Your information on Our Platform, You agree to the
            handling of Your information by the LLP in a manner as stated under
            this Policy and the AML Policy.
          </p>
          <p>
            We assume no liability for any disclosure of information due to
            errors in transmission, unauthorised third-party access or other
            acts of third parties, or acts or omissions beyond Our reasonable
            control and You agree that You will not hold Us responsible for any
            breach of security unless such breach has been caused as a direct
            result of Our gross negligence or wilful default.
          </p>
          <p>
            In using the Platform, You accept the inherent security implications
            of data transmission over the internet and the world wide web cannot
            always be guaranteed as completely secure. Therefore, Your use of
            the Platform will be at Your own risk.
          </p>
          <h2 style={{ color: "#04DA9A" }}>
            <span>5.</span> Use of Information
          </h2>

          <p>
            By the use of the Platform, You will be able to see the service
            offered / facilitated by Us. You may have interest or intention of
            availing such services or a general query with respect to the
            services available on the Platform and through the Platform.
            Accordingly, You may contact us. By contacting Us in anyway and by
            providing us Your phone number and contact details and other
            details, You thereby give Us the unequivocal right to:
          </p>

          <ol className="data1 p-3">
            <li style={{ color: "#04DA9A" }}>
              <span>
                {" "}
                Contact You through various mediums, including but not limited
                to phone calls, emails, etc. or make a phone call to You to
                discuss Your query or request
              </span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>
                Contact You through phone or message notwithstanding the fact
                that You may have registered under the Telecom Regulatory
                Authority of India regulations as a fully blocked or a partially
                blocked customer. It is further clarified that We shall only
                make solicited phone calls or messages;
              </span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>
                Share the contact details provided by You with the service
                provider or agent or representative available on or through the
                Platform who may be in a position to sufficiently answer or
                respond to Your query, including but not limited to third party
                service providers.
              </span>
            </li>
          </ol>

          <p>
            We understand that Your contact details are important to You, and We
            shall bind the service providers/agents/affiliates that We share
            Your contact details with, with the same level of protection as
            required under the applicable laws.
          </p>

          <h2 style={{ color: "#04DA9A" }}>
            <span>6.</span> Non- Disclosure of Information
          </h2>
          <p>
            You may choose not to provide identifiable information through the
            Platform, in which case You may not be allowed to access certain
            features / parts of the Platform. If You are a registered user, You
            may update, edit or correct Your account information and email
            preferences at any time by logging in to Your account. If You
            believe that any of Your information held by Us is inaccurate, You
            may write to Our Grievance Officer (“GO”) as detailed under Section
            10. We will respond to Your request within a period of thirty (30)
            days. It is Your responsibility to ensure that any information You
            provide Us remains accurate and updated.
          </p>

          <h2 style={{ color: "#04DA9A" }}>
            <span>7.</span> Links to Third Party Sites
          </h2>
          <p>
            Our Platform may, contain links to and from the Platforms of Our
            partner networks, affiliates and other third parties. The inclusion
            of a link does not imply any endorsement by Us of the third party
            Platform, the Platform's provider, or the information on the third
            party Platform. If You follow a link to any of these Platforms,
            please note that these Platforms may be governed by their own
            privacy policies and We disclaim all responsibility or liability
            with respect to these policies or the Platforms. Please check these
            policies and the terms of the Platforms before You submit any
            information to these Platforms.
          </p>

          <h2 style={{ color: "#04DA9A" }}>
            <span>8.</span> Restrictions and Liabilities
          </h2>
          <ol className="data1 p-3">
            <li style={{ color: "#04DA9A" }}>
              <span>
                {" "}
                We are liable only for any recommendations made by Our
                authorised person through the Platform
              </span>
            </li>
            <li style={{ color: "#04DA9A" }}>
              <span>
                We are not liable for any recommendations posted by any third
                party.
              </span>
            </li>
          </ol>
          <h2 style={{ color: "#04DA9A" }}>
            <span>9.</span> Severability and Exclusion
          </h2>
          <p>
            We have taken every effort to ensure that this Policy adheres with
            the applicable laws. The invalidity or unenforceability of any part
            of this Policy shall not prejudice or affect the validity or
            enforceability of the remainder of this Policy. This Policy does not
            apply to any information other than the information collected by the
            LLP through the Platform. This Policy shall be inapplicable to any
            unsolicited information You provide Us through this Platform or
            through any other means or through public channels. This includes,
            but is not limited to, information posted in any public areas of the
            Platform. All unsolicited information shall be deemed to be
            non-confidential and the LLP shall be free to use and/ or disclose
            such unsolicited information without any limitations.
          </p>

          <h2 style={{ color: "#04DA9A" }}>
            <span>10.</span> Changes to the Policy
          </h2>
          <p>
            We may update this Policy from time to time with or without any
            notice to You. We encourage You to periodically review the Policy
            for the latest information on Our privacy practices.
          </p>

          {/* <h2 style={{ color: "#04DA9A" }}>
            <span>11.</span> Contact
          </h2>
          <p>
            Please address Your grievances, feedback or questions, without
            limitation, with respect to the collection, processing, usage,
            disclosure, security of Your information in writing to our grievance
            officer, Mr. Vimal Sagar, at support@BitPlug.com or TO, Privacy Policy,
            # 512/10, 91 Springboard Business Hub Private Ltd Service Lane,
            Outer Ring Road, Mahadevapura, Bangalore – 560048, Karnataka, India.
          </p> */}
        </div>
      </div>
      <Footer />
    </>
  );
}
